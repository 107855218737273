export const useBodyScrollLock = (startingValue: boolean = false) => {
    onMounted(() => {
        setScrollLock(startingValue)
    })

    const setScrollLock = (val: boolean) => {
        if (val) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = ''
        }
    }

    return {
        setScrollLock,
    }
}
