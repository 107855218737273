<script lang="ts" setup>
import { PropType } from 'vue';
import { TranslationKey } from '~/i18n/TranslationKeys';
import { TextSizes } from '~~/src/constants/textSizes';

const props = defineProps({
    stepNames: { type: Object as PropType<TranslationKey[]>, required: true },
    currentStep: { type: Number, default: 0 }
})
const { stepNames, currentStep } = toRefs(props)

</script>

<template>
    <div class="flex flex-col gap-6">

        <!-- steps -->
        <div class="flex gap-1">

            <div v-for="(step, index) in stepNames" class="flex-1 flex flex-col gap-1.5 group">
                <CommonText :text="(step as TranslationKey)" :text-size="TextSizes.BODY_REGULAR"
                    :class="index === currentStep ? 'text-main-blue-600' : 'text-dark-blue-200'" />
                <div class="w-full h-1" :class="index === currentStep ? 'bg-main-blue-600' : 'bg-dark-blue-200'" />
            </div>

        </div>

        <!-- body -->
        <slot :name="`step-${currentStep}`"></slot>
    </div>
</template>