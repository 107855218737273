<script lang="ts" setup>
import { PropType } from 'vue';
import { TranslationKey } from '~/i18n/TranslationKeys';
import { SpSvg } from '~~/src/autogen/SpSvg';
import { IconSizes } from '~~/src/constants/iconSizes';
import { TextSizes } from '~~/src/constants/textSizes';

const props = defineProps({
    icon: { type: String as PropType<SpSvg>, required: true },
    text: { type: String as PropType<TranslationKey>, required: true }
})
const { icon, text } = toRefs(props)

</script>

<template>
    <div class="w-full flex gap-2 bg-yellow-100 rounded items-center p-2">
        <CommonIcon :icon-name="icon" :icon-size="IconSizes.XS" fill="fill-yellow-900" />
        <CommonText :text="text" :text-size="TextSizes.BODY_REGULAR" class="text-yellow-900" />
    </div>
</template>