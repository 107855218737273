<script lang="ts" setup>
import { useWindowSize, useElementSize } from '@vueuse/core'
import * as echarts from 'echarts'
import { PropType, Ref } from 'vue'
import { INamedArray } from '~/types/INamedArray'
import { utilGraph } from '~/utils/utilGraph'
import { Range } from '~~/src/submodules/sharedTypes/common/Range'
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency'
import { DataPoint } from '~~/src/submodules/sharedTypes/common/DataPoint'
import { utilNumber } from '~~/src/utils/UtilNumber'
import { utilDate } from '~~/src/utils/utilDate'
import { useLoadingStore } from '~/store/loading'
import { LoadingIds } from '~/constants/loadingIds'
import { basePricesConfiguration } from '~/config/BasePrices'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'

// dataValues[0] = base price, dataValues[1] = recommended price
const props = defineProps({
	previewValues: { type: Object as PropType<INamedArray<DataPoint<Date, number>>>, required: true },
	basePriceValues: { type: Object as PropType<INamedArray<DataPoint<Date, number>>>, required: true },
	currency: { type: Object as PropType<Currency>, required: true },
	class: { type: String, default: '' },
	style: { type: String, default: '' },
	selectedPeriod: { type: Object as PropType<Range<Date>>, required: false },
	displayFrom: { type: Date, default: new Date() },
	displayTo: { type: Date, default: new Date() },
	hideChart: { type: Boolean },
	showDaysOnly: { type: Boolean, default: false },
})
const {
	previewValues,
	basePriceValues,
	currency,
	class: _class,
	style,
	selectedPeriod,
	displayFrom,
	displayTo,
	hideChart,
	showDaysOnly,
} = toRefs(props)

const filterColors = ['#9E9B95', '#6D7AE8']
const backupPreviewValues = computed(() => previewValues.value.entries)
const backupBasePriceValues = computed(() => basePriceValues.value.entries)
const basePricesOnTail = computed(() => {
	const fromDate = basePriceValues.value.entries[basePriceValues.value.entries.length - 1].x
	const daysToGenerate = utilDate.daysDifference({
		from: utilDate.addDays(basePriceValues.value.entries[basePriceValues.value.entries.length - 1].x, 1),
		to: utilDate.addDays(new Date(), basePricesConfiguration.minimumDaysToFillWithPrices - 1),
	})

	const data = []
	for (let i = 0; i < daysToGenerate; i++) {
		data.push([
			{ xAxis: utilDate.formatDateForEChart(utilDate.addDays(fromDate, i)) },
			{ xAxis: utilDate.formatDateForEChart(utilDate.addDays(fromDate, i + 1)) },
		])
	}
	return data
})

// setup graph
const graphId = crypto.randomUUID()
const target = ref(null)
let chart: echarts.ECharts | undefined = undefined

// resize
watch(target, makeChart)

const { width } = useWindowSize()
watch(width, () => {
	if (chart != undefined) {
		chart.resize()
	}
})

const { width: containerWidth, height: containerHeight } = useElementSize(target)
watch([containerWidth, containerHeight], () => {
	if (chart != undefined) {
		chart.resize()
	}
})

// legend filter
const dataFilter: Ref<Boolean[]> = ref([true, true])
watch([previewValues, basePriceValues, displayFrom, displayTo], () => {
	dataFilter.value = [true, true]
	makeChart()
})
const onFilterClick = (index: number, value: boolean) => {
	dataFilter.value[index] = value
	makeChart()
}

// TODO:
const chartLoading = computed(() => false)

function makeChart() {
	// if element is not rendered yet, can't make chart.
	if (target.value == undefined) {
		return
	}

	// if chart is not available, create it
	if (chart == undefined) {
		const context = document.getElementById(graphId)
		chart = echarts.init(context!)
	}

	// if no data is available don't force a re-render, just let the loader handle it.
	if (chartLoading.value) {
		return
	}

	previewValues.value.entries = backupPreviewValues.value.filter(
		(el) => el.x >= displayFrom.value && el.x <= displayTo.value
	)
	basePriceValues.value.entries = backupBasePriceValues.value.filter(
		(el) => el.x >= displayFrom.value && el.x <= displayTo.value
	)

	const xValues = [basePriceValues.value, previewValues.value].reduce(
		(prev: Date[], next) => prev.concat(next.entries.map((el) => el.x)),
		[]
	)
	const yValues = [basePriceValues.value, previewValues.value]
		.reduce((prev: number[], next) => prev.concat(next.entries.map((el) => el.y)), [])
		.sort((prev, next) => prev - next)

	// need to calculate a variance (20%) to have space above on below the graph
	const maxValue = Math.max(...yValues.filter((value) => !Number.isNaN(value)))
	const minValue = Math.min(...yValues.filter((value) => !Number.isNaN(value)))
	const topVariance = maxValue + Math.max(Math.round(maxValue / 5), 1)
	const bottomVariance = minValue - Math.max(Math.round(minValue / 5), 1)

	const selectedPeriodExists =
		selectedPeriod?.value != undefined && selectedPeriod.value.from != undefined && selectedPeriod.value.to != undefined

	const options = {
		// tooltip: utilGraph.defaultTooltip(mapXLabel, mapYLabel),
		tooltip: {
			trigger: 'axis',
			formatter: (entry: any) => {
				const messageLines = entry.map((element: any) => {
					let base = `<div class="flex items-center justify-between w-full gap-3"> <span>${element.marker} ${element.seriesName}</span>`

					const needsReplacement = element.dataIndex > 0 && Number.isNaN(element.value[1])
					const value = needsReplacement ? basePriceValues.value.entries[element.dataIndex - 1].y : element.value[1]
					const formattedVal = utilNumber.toCurrency(value, currency.value)

					base += '<span>' + formattedVal + '</span>'

					return base + '</div>'
				})

				return `<span>${utilDate.formatTextualDate(
					new Date(entry[0].data[0]),
					useLocale().currentLocale.value
				)}</span><div class='flex flex-col'>${messageLines.join('')}</div>`
			},
			// valueFormatter: formatY,
			axisPointer: {
				type: 'cross',
				label: {
					// while it doesn't show, the formatting is used for the tooltip
					show: false,
				},
			},
		},

		xAxis: {
			type: 'time',
			splitNumber: showDaysOnly ? 4 : 7,
			axisLabel: {
				formatter: (function () {
					if (showDaysOnly.value) {
						return {
							day: '{dd}/{MM}',
							month: '{dd}/{MM}',
							year: '{bold|{yyyy}}',
						}
					}

					// we need to format in different ways the values based on the date range
					// first, if we have a year change, the the year is the bolder label
					if (displayFrom.value.getFullYear() !== displayTo.value.getFullYear()) {
						return {
							day: '{dd}/{MM}',
							month: '{MMM}',
							year: '{bold|{yyyy}}',
						}
					}

					// otherwise the month will be in bold
					return {
						day: '{dd}/{MM}',
						month: '',
					}
				})(),
				rich: {
					bold: {
						fontWeight: 'bold',
					},
				},
			},
		},
		yAxis: {
			type: 'value',
			data: yValues,
			min: bottomVariance,
			max: topVariance,
		},
		series: [
			{
				name: useLocale().translate(previewValues.value.name as TranslationKey),
				showSymbol: false,
				type: 'line',
				data: dataFilter.value[1]
					? previewValues.value.entries.map((el) => [utilDate.formatDateForEChart(el.x), el.y])
					: [],
				yAxisIndex: 0,
				xAxisIndex: 0,
				itemStyle: {
					color: filterColors[1],
				},
				smooth: true,
			},
			{
				name: useLocale().translate(basePriceValues.value.name as TranslationKey),
				showSymbol: false,
				type: 'line',
				data: dataFilter.value[0]
					? basePriceValues.value.entries.map((el) => [utilDate.formatDateForEChart(el.x), el.y])
					: [],
				yAxisIndex: 0,
				xAxisIndex: 0,
				itemStyle: {
					color: filterColors[0],
				},
				markArea: {
					opacity: 0.2,
					data: selectedPeriodExists
						? [
								[
									{ xAxis: utilDate.formatDateForEChart(selectedPeriod!.value!.from) },
									{ xAxis: utilDate.formatDateForEChart(selectedPeriod!.value!.to) },
								],
						  ]
						: [],
				},
				step: 'end',
			},

			{
				type: 'line',
				yAxisIndex: 0,
				xAxisIndex: 0,
				data: [],
				itemStyle: {
					// yellow-200
					color: '#FDE08A',
				},
				markArea: {
					opacity: 0.2,
					data: [
						...basePriceValues.value.entries
							.map((el, index) => {
								if (index == basePriceValues.value.entries.length - 1 || !Number.isNaN(el.y)) {
									return undefined
								}
								return [
									{ xAxis: utilDate.formatDateForEChart(el.x) },
									{ xAxis: utilDate.formatDateForEChart(basePriceValues.value.entries[index + 1].x) },
								]
							})
							.filter((el) => el != undefined),
						...basePricesOnTail.value,
					],
				},
				step: 'end',
			},
		],
		grid: utilGraph.defaultGrid,
	}

	chart.setOption(options, true)
}

const isPredictionsLoading = computed(() => {
	return useLoadingStore().isLoading(LoadingIds.GET_PREDICTION_PREVIEW)
})
</script>

<template>
	<!-- body -->
	<div class="relative flex flex-col w-full h-full gap-6 p-4" :class="_class">
		<div
			v-if="isPredictionsLoading"
			class="absolute z-10 flex items-center justify-center w-full h-full bg-dark-blue-700/80"
			:style="[style, 'transform: translate(-16px, -16px)'].join(' ')"
		>
			<LoaderJellyTriangle :size="80" />
		</div>

		<div
			v-if="hideChart"
			class="absolute z-10 flex items-center justify-center w-full h-full bg-dark-blue-700/80"
			:style="[style, 'transform: translate(-16px, -16px)'].join(' ')"
		>
			<CommonText :text="TranslationKeys.NO_BASE_PRICE_AVAILABLE" class="text-white" />
		</div>

		<!-- chart -->
		<div ref="target" :id="graphId" class="w-full h-44 grow" />

		<!-- chart filters -->
		<div class="flex flex-row gap-8 shrink-0">
			<ChartsLegend
				v-for="(data, index) in [basePriceValues, previewValues]"
				:key="`dataValue${index}`"
				:color="filterColors[index % filterColors.length]"
				:text="data.name"
				@change="(val: any) => onFilterClick(index, val)"
			/>
		</div>
	</div>
</template>
