<script lang="ts" setup>
import { useMobileInfos } from '~/hooks/useMobileInfos';

const { isMobile } = useMobileInfos()
</script>

<template>
    <CommonNotificationsManagerMobile v-if="isMobile" />
    <CommonNotificationsManagerDesktop v-else />
</template>
