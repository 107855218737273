import { SpSvg } from '~/autogen/SpSvg'
import { FeedbackTypes } from '../constants/FeedbackTypes'

export interface FeedbackButtonProps {
    bgColor: String
    fillColor: String
    icon: SpSvg
}

export const FeedbackButtonConfig: {
    [k in FeedbackTypes]: FeedbackButtonProps
} = {
    [FeedbackTypes.BAD]: {
        bgColor: 'bg-red-400 hover:bg-red-300 active:bg-red-600',
        fillColor: 'fill-red-900 group-active:fill-white',
        icon: SpSvg.BasicEmojiSad,
    },
    [FeedbackTypes.NEUTRAL]: {
        bgColor: 'bg-yellow-400 hover:bg-yellow-300 active:bg-yellow-600',
        fillColor: 'fill-yellow-900 group-active:fill-white',
        icon: SpSvg.BasicEmojiQuite,
    },
    [FeedbackTypes.POSITIVE]: {
        bgColor: 'bg-green-400 hover:bg-green-300 active:bg-green-600',
        fillColor: 'fill-green-900 group-active:fill-white',
        icon: SpSvg.BasicEmojiHappy,
    },
}
