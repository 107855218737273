import { EventType } from '../submodules/sharedTypes/common/Event'

export interface IColorThreshold {
	background: string
	text: string
	upTo: number
}

export interface IEventDetailsConfig {
	colors: {
		[k in EventType]: IColorThreshold[]
	}
}

export const eventDetailsConfig: IEventDetailsConfig = {
	colors: {
		[EventType.Event]: [
			{
				background: 'bg-events-100',
				text: 'text-dark-blue-900',
				upTo: 0.1,
			},
			{
				background: 'bg-events-200',
				text: 'text-dark-blue-900',
				upTo: 0.25,
			},
			{
				background: 'bg-events-300',
				text: 'text-dark-blue-900',
				upTo: 0.45,
			},
			{
				background: 'bg-events-400',
				text: 'text-dark-blue-900',
				upTo: 0.7,
			},
			{
				background: 'bg-events-500',
				text: 'text-dark-blue-900',
				upTo: 1,
			},
			{
				background: 'bg-events-600',
				text: 'text-black',
				upTo: 99999,
			},
		],
		[EventType.Holiday]: [
			{
				background: 'bg-festivities-100',
				text: 'text-dark-blue-900',
				upTo: 0.1,
			},
			{
				background: 'bg-festivities-200',
				text: 'text-dark-blue-900',
				upTo: 0.25,
			},
			{
				background: 'bg-festivities-300',
				text: 'text-dark-blue-900',
				upTo: 0.45,
			},
			{
				background: 'bg-festivities-400',
				text: 'text-dark-blue-900',
				upTo: 0.7,
			},
			{
				background: 'bg-festivities-500',
				text: 'text-white',
				upTo: 1,
			},
			{
				background: 'bg-festivities-600',
				text: 'text-white',
				upTo: 99999,
			},
		],
	},
}
