<script lang="ts" setup>
import { PropType } from 'vue'
import { EmitsEnum } from '~~/src/constants/emits'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { TextSizes } from '../../constants/textSizes'
import { UserflowId } from '../../constants/UserflowId'
import { TrackingMessages } from '../../constants/trackingMessages'
import _ from 'lodash'

const props = defineProps({
	disabled: { type: Boolean, default: false },
	selectedAccommodationId: { type: Number, default: () => useAccommodationsStore().accommodations[0].id },
	getChild: { type: Function as PropType<(roomId: number) => any>, default: () => undefined },
	getChildProps: { type: Function as PropType<(pos: number) => any>, default: () => undefined },
	getCheckboxProps: { type: Function as PropType<(pos: number) => any>, default: () => {} },
	testIds: {
		type: Object as PropType<{
			'accommodation-dropdown'?: string
			'search-input'?: string
			'room-type-list'?: string
			'select-all'?: string
			'tweak-left-button'?: string
			'tweak-right-button'?: string
			'remover-modifier-container'?: string
			'filters-container'?: string
		}>,
		default: () => ({}),
	},
})
const { disabled, getChild, getChildProps, getCheckboxProps, selectedAccommodationId, testIds } = toRefs(props)
const emit = defineEmits([EmitsEnum.Change, EmitsEnum.Select])

const accommodationStore = useAccommodationsStore()
const _accommodationId = ref(selectedAccommodationId.value)

const onAccommodationChange = (accommodationId: number) => {
	_accommodationId.value = accommodationId

	emit(EmitsEnum.Change, [])
	emit(EmitsEnum.Select, accommodationId)
}

const onRoomTypeChange = (selectedRoomTypeIds: number[], selectedAccommodationId: number) => {
	emit(EmitsEnum.Change, selectedRoomTypeIds, selectedAccommodationId)
}

const labelText = computed(() =>
	accommodationStore.accommodations.length > 1
		? TranslationKeys.STRUCTURE
		: (accommodationStore.accommodations[0].name as TranslationKeys)
)

const searchTermValue = ref<string>('')

const searchTerm = ref<string>('')
const onSearch = (str: string) => {
	_.debounce(() => {
		searchTerm.value = str.toLowerCase()
		utilTracking.track(TrackingMessages.ROOM_TYPE_SEARCH, {})
	}, 500)()
}
</script>

<template>
	<div class="flex min-w-full flex-col gap-4 rounded bg-dark-blue-50 p-2" :data-id="UserflowId.RoomTypeList">
		<CommonLabel :text="labelText" :inline="false" :text-size="TextSizes.PRODUCTIVE">
			<AccommodationDropdown
				:selected-id="_accommodationId"
				:data-testid="testIds?.['accommodation-dropdown']"
				@change="onAccommodationChange"
				:disabled="disabled"
			/>
			<SpInputSearch
				:data-testid="testIds?.['search-input']"
				@update:model-value="onSearch"
				v-model="searchTermValue"
				class="w-full"
				:placeholder="useLocale().translate(TranslationKeys.ACCOMMODATIONS_SEARCH_ROOM_PLACEHOLDER)"
			/>
			<AnimationCollapse>
				<RoomTypeList
					v-if="!disabled"
					selectAllAllowed
					:accommodation-id="_accommodationId"
					class="w-full"
					:get-child="getChild"
					:search-term="searchTerm"
					:data-testid="testIds?.['room-type-list']"
					:testIds="testIds"
					:get-child-props="getChildProps"
					:get-checkbox-props="getCheckboxProps"
					@change="onRoomTypeChange"
				/>
			</AnimationCollapse>
		</CommonLabel>
	</div>
</template>
