<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { popupConfig } from '~~/src/config/popupConfig'
import { getPopupById } from '~~/src/config/PopupMap'
import { tooltipIconConfig } from '~~/src/config/tooltipIconConfig'
import { Directions } from '~~/src/constants/directions'
import { IdPopup } from '~~/src/constants/IdPopup'
import { usePopupStore } from '~~/src/store/popup'

const popupStore = usePopupStore()

const getComponent = (id: IdPopup) => getPopupById(id)?.popup
const getProps = (popup: any) => popup.props

const getPopupPosition = (popup: any) => {
	const associatedRect = popup.associatedRect
	if (associatedRect == undefined) {
		return ''
	}
	const additionalTopSpace = popup.alignTriangle ? 0 : popupConfig.distanceFromTopInPixel

	const style = `top: ${associatedRect.y + additionalTopSpace}px;`
	const origin = associatedRect.x + associatedRect.width
	const breakPoint = window.innerWidth / 2

	if (origin > breakPoint) {
		const position = window.innerWidth - associatedRect.x + 20
		return style + ` right: ${position}px;`
	}

	const position = origin + 20
	return style + ` left: ${position}px;`
}

const hintSide = (popup: any) => {
	const associatedRect = popup?.associatedRect
	if (associatedRect == undefined) {
		return Directions.LEFT
	}

	const origin = associatedRect.x + associatedRect.width
	const breakPoint = window.innerWidth / 2
	return origin > breakPoint ? Directions.LEFT : Directions.RIGHT
}

const displayPopup = computed(() => popupStore.popups.length > 0)
const popups = computed(() => popupStore.popups)

const popupManagerRef = ref()
onClickOutside(popupManagerRef, () => {
	popupStore.closeTopPopup()
})

const additionalClasses = (currentPopup: any) =>
	[currentPopup.backgroundColor, currentPopup.borderColor, currentPopup.borderWidth].join(' ')
</script>

<template>
	<div v-if="displayPopup" id="popup-manager" ref="popupManagerRef">
		<div
			v-for="currentPopup in popups"
			class="shadow-high-contrast absolute z-[15] rounded-md"
			:style="getPopupPosition(currentPopup)"
			@click.stop="() => {}"
			:class="additionalClasses(currentPopup)"
		>
			<div class="flex items-center justify-center">
				<component :is="getComponent(currentPopup.id)" v-bind="getProps(currentPopup)" />
			</div>
		</div>
	</div>
</template>

<style scoped>
.hint-triangle {
	transform: translateX(var(--translate-x)) translateY(50%) rotate(45deg);
}
</style>

<style>
.popup-group-item {
	transition: all 1s;
}

.popup-group-leave-to,
.popup-group-enter-from {
	opacity: 0;
}

.popup-group-enter-to,
.popup-group-leave-from {
	opacity: 1;
}
</style>
