<script lang="ts" setup>
import { PropType } from 'vue'
import { weekDaysMobile, weekDaysShort } from '~/constants/weekdays'
import { useMobileInfos } from '~/hooks/useMobileInfos'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { UserflowId } from '~~/src/constants/UserflowId'
import { EmitsEnum } from '~~/src/constants/emits'
import { TextSizes } from '~~/src/constants/textSizes'
import { utilArray } from '~~/src/utils/utilArray'

const props = defineProps({
	disabled: { type: Boolean, default: false },
	selectedRange: { type: Object as PropType<number[]>, default: [0, 1, 2, 3, 4, 5, 6, 7] },
	disabledDays: { type: Object as PropType<number[]>, default: [] },
	testIds: {
		type: Object as PropType<{
			range?: string[]
			days?: string[]
		}>,
		default: () => ({}),
	},
})
const { disabled, selectedRange, disabledDays, testIds } = toRefs(props)
const selectedDays = ref(selectedRange.value)
watch(selectedRange, () => (selectedDays.value = selectedRange.value))

const { isMobile } = useMobileInfos()

// N.B: these are not days... these are positional values
const weekDays = [0, 1, 2, 3, 4]
const weekendDays = [5, 6]

const emit = defineEmits([EmitsEnum.Change])
const onDayChange = (days: number[]) => emit(EmitsEnum.Change, days)

const weekSelectors = [TranslationKeys.WORKING_DAYS, TranslationKeys.WEEKEND]
const selectedWeekCategories = computed(() => {
	const categories = []

	// regularWeek
	if (utilArray.sortedSubsetInSortedArray(weekDays, selectedDays.value)) {
		categories.push(0)
	}
	// weekEnd
	if (utilArray.sortedSubsetInSortedArray(weekendDays, selectedDays.value)) {
		categories.push(1)
	}

	return categories
})

const filteredWeekCategories = computed(() => {
	const result = []
	if (utilArray.anyMatchInSortedArrays(weekDays, disabledDays.value)) {
		result.push(0)
	}
	if (utilArray.anyMatchInSortedArrays(weekendDays, disabledDays.value)) {
		result.push(1)
	}

	return result
})

const onCategoryChange = (numbers: number[]) => {
	const days = [weekDays, weekendDays]
	let newVal = [...selectedDays.value]

	// entries added
	numbers.forEach((newEntries) => {
		const entryExistsAlready = selectedWeekCategories.value.some((entry) => entry === newEntries)
		if (entryExistsAlready) {
			return
		}
		newVal = utilArray.mergeSortedArrays<number>(newVal, days[newEntries])
	})

	// entries removed
	selectedWeekCategories.value.forEach((oldEntries) => {
		const entryExistsAlready = numbers.some((entry) => entry === oldEntries)
		if (entryExistsAlready) {
			return
		}
		newVal = utilArray.removeSortedSubsetFromSortedArray(days[oldEntries], newVal)
	})

	emit(EmitsEnum.Change, utilArray.removeDuplicates(newVal))
}
</script>

<template>
	<div class="flex flex-col gap-3" :data-id="UserflowId.DaysOfTheWeekSelector">
		<CommonText :text="TranslationKeys.WEEK_DAYS" :text-size="TextSizes.PRODUCTIVE" />
		<CommonMultipleRangeSelector
			:entries="weekSelectors"
			:selected-options="selectedWeekCategories"
			@click="onCategoryChange"
			:testIds="testIds?.range"
			:disabled="disabled"
			:custom-sizes="[5, 2]"
			:disabled-options="filteredWeekCategories"
		/>
		<CommonMultipleRangeSelector
			:entries="isMobile ? weekDaysMobile : weekDaysShort"
			:selected-options="selectedDays"
			@click="onDayChange"
			:testIds="testIds?.days"
			:disabled="disabled"
			:disabled-options="disabledDays"
		/>
	</div>
</template>
