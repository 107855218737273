<script lang="ts" setup>
import { PropType } from 'vue';
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys';
import { FeedbackTypes } from '~~/src/constants/FeedbackTypes';
import { IdModal } from '~~/src/constants/IdModal';
import { TextSizes } from '~~/src/constants/textSizes';
import { TrackingMessages } from '~~/src/constants/trackingMessages';
import { useFeedbackStore } from '~~/src/store/feedback';
import { useModalStore } from '~~/src/store/modals';
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration';
import { utilTracking } from '~~/src/utils/utilTracking';

const props = defineProps({
    config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
    modalId: { type: String as PropType<IdModal>, required: true }
})
const { config, modalId } = toRefs(props)
const feedbackstore = useFeedbackStore()

const step = ref(0)
const currentJudgement = ref(FeedbackTypes.POSITIVE)

// Data
const judgementScore = computed(() => {
    switch (currentJudgement.value) {
        case FeedbackTypes.POSITIVE: {
            return 10
        }
        case FeedbackTypes.BAD: {
            return 1
        }
        default: {
            return 5
        }
    }
})
const judgementLabel = computed(() => {
    switch (currentJudgement.value) {
        case FeedbackTypes.POSITIVE: {
            return TranslationKeys.ADD_MORE_POSITIVE
        }
        case FeedbackTypes.BAD: {
            return TranslationKeys.ADD_MORE_NEGATIVE
        }
        default: {
            return TranslationKeys.ADD_MORE_MILD
        }
    }
})
const judgementText = ref("")
const updateText = (text: string) => judgementText.value = text
const judgementContactConsent = ref(false)
const updateConsent = (confirmed: boolean) => judgementContactConsent.value = confirmed
const nextStep = (judgement: FeedbackTypes) => {
    step.value = 1
    currentJudgement.value = judgement

    utilTracking.track(TrackingMessages.FEATURE_SCORE_GET, {
        feature_name: feedbackstore.activeFeedback!.eventKey,
        feature_score: judgementScore.value,
    })
}

const onFeedback = () => {
    step.value = 2

    if ((judgementText.value || '').trim() != '') {
        utilTracking.track(TrackingMessages.FEATURE_FEEDBACK_GET, {
            feature_name: feedbackstore.activeFeedback!.eventKey,
            contact_me: judgementContactConsent.value
        })
    }

    useModalStore().closeModal(modalId.value)
}

const onFeedbackReject = () => {
    switch (step.value) {
        case 0: {
            // step 0: no emoji selected
            feedbackstore.rejectFeedback()
            break;
        } case 1: {
            // step 1: emoji selected
            feedbackstore.giveFeedback({
                eventId: feedbackstore.activeFeedback!.id,
                feedbackScore: judgementScore.value,
                canBeContacted: false,
            })
            break;
        } case 2: {

            feedbackstore.giveFeedback({
                eventId: feedbackstore.activeFeedback!.id,
                feedbackScore: judgementScore.value,
                canBeContacted: judgementContactConsent.value,
                feedbackComment: judgementText.value
            })
            break;
        }
    }
}

const close = () => {
    useModalStore().closeModal(modalId.value)
}

const configToForward = computed(() => {
    const baseConfig = {
        ...config.value,
        onClose: onFeedbackReject,
        title: { key: feedbackstore.activeFeedback!.contents.title as TranslationKey },
        label: { key: feedbackstore.activeFeedback!.contents.category as TranslationKey },
        text: feedbackstore.activeFeedback!.contents.description as TranslationKey,
    }
    if (step.value == 0) {
        return baseConfig
    }

    return {
        ...baseConfig,
        cancelButton: {
            text: TranslationKeys.CLOSE,
            effect: close
        },
        buttons: [
            {
                effect: onFeedback,
                text: TranslationKeys.SEND
            }
        ]
    }
})

</script>

<template>
    <ModalGeneric :modal-id="modalId" :config="configToForward" class="w-full">

        <!-- first screen -->
        <div v-if="step === 0" class="flex flex-col gap-2 pb-4">
            <CommonText :text="TranslationKeys.FEEDBACK_EVALUATE" :text-size="TextSizes.BODY_SEMIBOLD" />
            <div class="flex gap-4">
                <FeedbackButton v-for="feedbackType in FeedbackTypes" :feedback-type="feedbackType"
                    @click="nextStep(feedbackType)" />
            </div>
        </div>

        <!-- second screen -->
        <div v-else class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
                <CommonText :text="judgementLabel" :text-size="TextSizes.BODY_SEMIBOLD" />
                <CommonTextareaInput :placeholder="TranslationKeys.SUGGESTIONS_HERE" @change="updateText" :value="judgementText" />
            </div>
            <CommonCheckbox :text="TranslationKeys.CONTACT_TO_IMPROVE_SMARTPRICING" @toggle="updateConsent"
                :clicked="judgementContactConsent" no-truncate />
        </div>
    </ModalGeneric>
</template>