<script lang="ts" setup>
import { PropType, Ref } from 'vue'
import { TestIds } from '~/constants/TestIds'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { IdModal } from '~~/src/constants/IdModal'
import { PeriodSelectorMode } from '~~/src/constants/periodSelectorModes'
import { TrackingMessages } from '~~/src/constants/trackingMessages'
import { UserflowId } from '~~/src/constants/UserflowId'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useCalculatedQuotationsStore } from '~~/src/store/calculatedQuotation'
import { DateRange } from '~~/src/submodules/sharedTypes/common/DateRange'
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration'
import { utilNumber } from '~~/src/utils/UtilNumber'
import { utilTracking } from '~~/src/utils/utilTracking'

const props = defineProps({
	config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
	modalId: { type: String as PropType<IdModal>, required: true },
})
const { config } = toRefs(props)
const quotationsStore = useCalculatedQuotationsStore()
const accommodationStore = useAccommodationsStore()
quotationsStore.resetQuotation()

const date: Ref<undefined | DateRange> = ref()
const onDateChange = (newVal: any) => {
	date.value = newVal
	buttonDisabled.value = selectedRoomTypes.value.length == 0
}

const selectedRoomTypes: Ref<number[]> = ref([])
const selectedAccommodation = ref()
const calculatedCurrency = ref()
const calculatedAlready = ref(false)
const buttonDisabled = ref(!calculatedAlready.value && selectedRoomTypes.value.length < 1)

const onCalculate = () => {
	calculatedAlready.value = true
	buttonDisabled.value = true
	calculatedCurrency.value = accommodationStore.getAccommodationById(selectedAccommodation.value)!.currency

	quotationsStore.requestQuotation(selectedRoomTypes.value, date.value!)
}

const trackCopy = () => {
	utilTracking.track(TrackingMessages.PRICE_COPY, {
		nights: quotationsStore.nightsAmount,
		rooms: quotationsStore.roomAmount,
	})
}

const onRoomTypeChange = (idList: number[], accommodationId: number) => {
	calculatedAlready.value = false
	buttonDisabled.value = idList.length == 0
	selectedRoomTypes.value = idList
	quotationsStore.resetQuotation()
	selectedAccommodation.value = accommodationId
}

const configToForward = computed(() => ({
	...config.value,
	buttons: [{ ...config.value.buttons![0]!, effect: onCalculate, disabled: buttonDisabled }],
}))
</script>

<template>
	<ModalGeneric
		:modal-id="modalId"
		:config="configToForward"
		style="width: 600px"
		:data-testid="TestIds.CalendarCalculatePopup"
	>
		<template v-slot:default>
			<div class="flex flex-col gap-6">
				<DatePeriodSelector
					@change="onDateChange"
					:date="date"
					:start-date="new Date()"
					:data-id="UserflowId.QuotationRangeSelector"
					:data-testid="TestIds.CalendarCalculatePopupPeriodField"
					:selector-mode="PeriodSelectorMode.DAYS"
					:test-ids="{
						'date-picker': TestIds.CalendarCalculatePopupPeriodField,
					}"
				/>
				<AccommodationDropdownList
					:test-ids="{
						'accommodation-dropdown': TestIds.CalendarCalculatePopupAccomodationField,
						'room-type-list': TestIds.CalendarCalculatePopupRoomsContainer,
						'select-all': TestIds.CalendarCalculatePopupSelectallCheckbox,
					}"
					:disabled="date == undefined"
					@change="onRoomTypeChange"
				/>
			</div>
		</template>
		<template v-slot:footer>
			<AnimationCollapse>
				<CommonLabel
					v-if="quotationsStore.calculatedQuotation != undefined"
					:text="TranslationKeys.CALCULATED_PRICE"
					class="mt-6"
				>
					<CommonClipboardText
						:text="utilNumber.toCurrency(quotationsStore.calculatedQuotation, calculatedCurrency)"
						:on-click-message="TranslationKeys.CLIPBOARD_PRICE_SAVED"
						@click="trackCopy"
						:data-id="UserflowId.PriceCalculationResult"
					/>
				</CommonLabel>
			</AnimationCollapse>
		</template>
	</ModalGeneric>
</template>
