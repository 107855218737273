import { ModifierType } from './../submodules/sharedTypes/common/Modifiers'

export interface IQuickActionConfig {
    borderDivide: string
}

export const quickActionConfig: { [k in ModifierType]: IQuickActionConfig } = {
    [ModifierType.Frozen]: {
        borderDivide: 'border-main-blue-200',
    },
    [ModifierType.Max]: {
        borderDivide: 'border-light-blue-200',
    },
    [ModifierType.Min]: {
        borderDivide: 'border-light-blue-200',
    },
    [ModifierType.Tweak]: {
        borderDivide: 'border-red-200',
    },
}
