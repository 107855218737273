<script lang="ts" setup>
import { PropType } from 'vue';
import { FeedbackButtonConfig } from '~~/src/config/FeedbackButtonConfig';
import { EmitsEnum } from '~~/src/constants/emits';
import { FeedbackTypes } from '~~/src/constants/FeedbackTypes';
import { IconSizes } from '~~/src/constants/iconSizes';

const props = defineProps({
    feedbackType: { type: String as PropType<FeedbackTypes>, required: true }
})
const { feedbackType } = toRefs(props)
const emit = defineEmits([EmitsEnum.Click])

const onClick = () => emit(EmitsEnum.Click)

const config = computed(() => FeedbackButtonConfig[feedbackType.value])
</script>

<template>
    <div class="rounded-md p-2 w-fit cursor-pointer group" :class="config.bgColor" @click="onClick">
        <CommonIcon :fill="(config.fillColor as string)" :icon-name="config.icon" :icon-size="IconSizes.M" />
    </div>
</template>