<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core';
import * as echarts from 'echarts';
import { PropType, Ref } from 'vue';
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys';
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency';
import { RoomTypeEventImpactPreviewData } from '~~/src/submodules/sharedTypes/communication/events/preview/GetEventImpactPreviewResponse';
import { utilDate } from '~~/src/utils/utilDate';
import { utilNumber } from '~~/src/utils/UtilNumber';

const props = defineProps({
    eventPreview: { type: Object as PropType<RoomTypeEventImpactPreviewData[]>, required: true },
    eventWithModifiersPreview: { type: Object as PropType<RoomTypeEventImpactPreviewData[]>, required: true },
    currentPreview: { type: Object as PropType<RoomTypeEventImpactPreviewData[]>, required: true },
    currency: { type: Object as PropType<Currency>, required: true },
    selectedRoomType: { type: Number, required: true },
    dataSelectionRequired: { type: Boolean, default: false }
})
const { currency, currentPreview, eventPreview, eventWithModifiersPreview, selectedRoomType, dataSelectionRequired } = toRefs(props)
const filterColors = ['#818D9C', '#3BCED9', '#FAB425']

const dataValues = computed(() => {
    const cleanEntries = (price: any) => [utilDate.formatDateForEChart(price.date), price.price]

    const mappedEvent = {
        name: TranslationKeys.PREVIEW,
        entries: eventPreview.value.find(el => el.id === selectedRoomType.value)?.prices.map(cleanEntries) || []
    }

    const mappedCurrent = {
        name: TranslationKeys.CURRENT_STRATEGY,
        entries: currentPreview.value.find(el => el.id === selectedRoomType.value)?.prices.map(cleanEntries) || []
    }

    const mappedEventWithModifiers = {
        name: TranslationKeys.PREVIEW_PLUS_MODIFIERS,
        entries: eventWithModifiersPreview.value.find(el => el.id === selectedRoomType.value)?.prices.map(cleanEntries) || []
    }

    return [mappedCurrent, mappedEvent, mappedEventWithModifiers]
})


// setup graph
const graphId = crypto.randomUUID()
const target = ref(null)
let chart: echarts.ECharts | undefined = undefined

// resize
watch(target, makeChart)

const { width } = useWindowSize()
watch(width, () => {
    if (chart != undefined) {
        chart.resize()
    }
})

// legend filter
const dataFilter: Ref<Boolean[]> = ref(dataValues.value.map(() => true))

watch(dataValues, makeChart)

const onFilterClick = (index: number, value: boolean) => {
    dataFilter.value[index] = value
    makeChart()
}

const chartLoading = computed(() => dataValues.value[0].entries.length == 0 || dataValues.value[1].entries.length == 0)

function makeChart() {
    // if element is not rendered yet, can't make chart.
    if (target.value == undefined) {
        return
    }

    // if chart is not available, create it
    if (chart == undefined) {
        const context = document.getElementById(graphId)
        chart = echarts.init(context!)
    }

    // removing the occupancy
    // the following ts-ignore is because time type is weird in echart
    // @ts-ignore
    const yValues = dataValues.value
        .map(el => el.entries
            .map(el => el[1]))
        .reduce((prev, next) => prev.concat(next), [])
        .sort((prev, next) => prev - next)

    // 20% of the variance (1/5 = 0.2)
    let variance = Math.round((yValues[yValues.length - 1] - yValues[0]) / 5)
    // this is added in case there is a very small variance (e.g: 1,2,3,4)
    variance = Math.max(variance, 1)

    const options = {
        tooltip: {
            trigger: 'axis',
            formatter: (entry: any) => {
                const messageLines = entry.map((element: any) => {
                    let base = `<div class="flex items-center justify-between w-full gap-3"> <span>${element.marker} ${element.seriesName}</span>`
                    const formattedVal = utilNumber.toCurrency(element.value[1], currency.value)
                    base += "<span>" + formattedVal + "</span>"
                    return base + "</div>"
                });

                return `<span>${utilDate.formatTextualDate(new Date(entry[0].axisValueLabel), useLocale().currentLocale.value)}</span><div class='flex flex-col'>${messageLines.join('')}</div>`
            },
            // valueFormatter: formatY,
            axisPointer: {
                type: 'cross',
                label: {
                    // while it doesn't show, the formatting is used for the tooltip
                    show: false,
                },
            },
        },
        xAxis: {
            show: true,
            type: 'time',
            splitNumber: 4,
        },
        yAxis: [
            {
                type: 'value',
                min: Math.round(yValues[yValues.length - 1] + variance),
                max: Math.round(Math.max(yValues[0] - variance, 0)),
                axisLabel: {
                    formatter: (val: any) => utilNumber.toCurrency(val, currency.value)
                }
            },
        ],
        series: dataValues.value.map((entry, index) => {
            // @ts-ignore
            let data = dataFilter.value[index] ? entry.entries : []

            const config = {
                name: useLocale().translate(entry.name as TranslationKey),
                type: 'line',
                data,
                xAxisIndex: 0,
                yAxisIndex: 0,
                showSymbol: false,
                itemStyle: {
                    color: filterColors[index % filterColors.length],
                },
            }

            return config
        }),
        grid: {
            top: 5,
            right: 0,
            bottom: 5,
            left: 5,
            containLabel: true,
        }
    };

    chart.setOption(options, true)
}

</script>

<template>
    <div class="relative h-full">

        <!-- body -->
        <div class="flex flex-col w-full gap-6 p-4 h-full">

            <!-- chart -->
            <div class="relative h-full">
                <div ref="target" :id="graphId" class="w-full h-40" />
            </div>

            <!-- chart filters -->
            <div class="flex flex-row flex-wrap gap-8">
                <ChartsLegend v-for="(data, index) in dataValues" :key="`dataValue${index}`"
                    :color="filterColors[index % filterColors.length]" :text="data.name"
                    @change="(val: any) => onFilterClick(index, val)" :is-enabled="(dataFilter[index] as boolean)" />
            </div>

            <div v-if="dataSelectionRequired"
                class="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-white/60" />
            <div v-else-if="chartLoading"
                class="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-dark-blue-700/60">
                <LoaderJellyTriangle :size="80" />
            </div>
        </div>
    </div>
</template>