<script lang="ts" setup>
import { differenceInDays } from 'date-fns'
import { PropType } from 'vue'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'
import { EmitsEnum } from '~~/src/constants/emits'
import { PeriodSelectorMode } from '~~/src/constants/periodSelectorModes'
import { TextSizes } from '~~/src/constants/textSizes'
import { DateRange } from '~~/src/submodules/sharedTypes/common/DateRange'

const props = defineProps({
	date: { type: Object as PropType<DateRange | undefined> },
	startDate: { type: Date },
	selectorMode: { type: String as PropType<PeriodSelectorMode>, default: PeriodSelectorMode.NIGHTS },
	testIds: {
		type: Object as PropType<{
			'date-picker'?: string
			field?: string
		}>,
		default: () => ({}),
	},
})
const { date, startDate, selectorMode } = toRefs(props)
const emit = defineEmits([EmitsEnum.Change])

const selectedNightsLabel = computed(() => {
	if (date?.value != undefined) {
		let days = Math.abs(differenceInDays(date.value.from, date.value.to))
		if (selectorMode.value == PeriodSelectorMode.NIGHTS) {
			days++
		}
		const locaKey = days != 1 ? TranslationKeys.NIGHTS : TranslationKeys.NIGHT

		return days.toString() + ' ' + useLocale().translate(locaKey).toLocaleLowerCase()
	}
	return ''
})

const onDateChange = (newVal: any) => {
	date!.value = newVal
	emit(EmitsEnum.Change, newVal)
}
</script>

<template>
	<div>
		<CalendarRange :data="date" @change="onDateChange" :start-date="startDate" :test-ids="testIds" />
		<CommonText :text="selectedNightsLabel as TranslationKey" :text-size="TextSizes.PRODUCTIVE" />
	</div>
</template>
