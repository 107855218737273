<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { IconSizes } from '~~/src/constants/iconSizes'
import { IdModal } from '~~/src/constants/IdModal'
import { SIZE_TABLET_LANDSCAPE } from '~~/src/constants/screenSizes'
import { TextSizes } from '~~/src/constants/textSizes'
import { UserflowId } from '~~/src/constants/UserflowId'
import { useModalStore } from '~~/src/store/modals'
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration'
import { TestIds } from '../../constants/TestIds'

const props = defineProps({
	config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
	modalId: { type: String as PropType<IdModal>, required: true },
	isMobileModal: { type: Boolean, default: false },
})
const { config, modalId, isMobileModal } = toRefs(props)

const slots = useSlots()
const hasDefaultSlot = computed(() => slots.default != undefined)
const hasFooterSlot = computed(() => slots.footer != undefined)
const modalStore = useModalStore()

const { width } = useWindowSize()
const isMobile = computed(() => width.value <= SIZE_TABLET_LANDSCAPE)
watch(width, () => {
	const visualizationModeMismatch =
		// mobile on desktop res
		(!isMobile.value && isMobileModal.value) ||
		// desktop on mobile res
		(isMobile.value && !isMobileModal.value)

	if (visualizationModeMismatch) {
		modalStore.closeModal(modalId.value)
	}
})

const hasButtons = computed(() => {
	const conf = config.value

	return (
		(conf.buttons != undefined && conf.buttons.length > 0) ||
		conf.cancelButton != undefined ||
		conf.dangerButton != undefined
	)
})

const getGenericButtons = computed(() => config.value.buttons || [])

const isCancelOnLeft = computed(() => {
	const conf = config.value

	let buttonCount = (conf.buttons || []).length
	if (conf.dangerButton) {
		buttonCount++
	}

	return buttonCount > 1 && conf.cancelButton != undefined
})

const onClose = () => {
	modalStore.closeModal(modalId.value)
}

const getButtonType = (index: number): ButtonTypes => {
	if (config.value.dangerButton != undefined) {
		return config.value.buttons![index]?.buttonType || ButtonTypes.TERTIARY
	}

	return config.value.buttons![index]?.buttonType || index == getGenericButtons.value.length - 1
		? ButtonTypes.PRIMARY
		: ButtonTypes.TERTIARY
}

const getTextReplacements = computed(() =>
	config.value.textReplacements == undefined ? [] : config.value.textReplacements()
)
const getTitleReplacements = computed(() =>
	config.value.titleReplacements == undefined ? [] : config.value.titleReplacements()
)

onBeforeUnmount(() => {
	if (config.value.onClose != undefined) {
		config.value.onClose()
	}
})
</script>

<template>
	<div
		class="relative flex flex-col gap-5 rounded-md bg-white p-4 shadow-md lg:w-96"
		style="max-height: 90%"
		:data-testid="config.testIds?.container && TestIds[config.testIds.container]"
	>
		<div class="flex flex-col">
			<CommonText
				v-if="config.label"
				:text-size="TextSizes.PRODUCTIVE"
				:text="config.label"
				class="mb-1 text-dark-blue-500"
			/>
			<CommonText
				:text-size="TextSizes.HEADING_MD_REGULAR"
				:text="config.title.key"
				:replacements="getTitleReplacements"
				class="text-main-blue-600"
			/>
		</div>
		<div v-if="config.allowClose" class="absolute right-5 top-5">
			<CommonButton
				:type="ButtonTypes.GHOST"
				:size="ButtonSizes.ICON_S"
				@click="onClose"
				:data-id="UserflowId.ModalClose"
			>
				<CommonIcon
					:icon-name="SpSvg.BasicTimes"
					:icon-size="IconSizes.XS"
					:data-testid="config.testIds?.closeIcon && TestIds[config.testIds.closeIcon]"
				/>
			</CommonButton>
		</div>
		<CommonText
			v-if="config.text"
			:text="config.text"
			:text-size="TextSizes.BODY_REGULAR"
			:replacements="getTextReplacements"
			class="text-black"
		/>
		<div v-if="hasDefaultSlot" class="overflow-y-auto">
			<slot></slot>
		</div>
		<div v-if="hasFooterSlot">
			<slot name="footer"></slot>
		</div>
		<div v-if="hasButtons" class="flex flex-row justify-between">
			<!-- left buttons -->
			<div class="pt-4">
				<CommonButton
					v-if="isCancelOnLeft"
					:type="config.cancelButton!.buttonType || ButtonTypes.SECONDARY"
					@click="config.cancelButton!.effect"
					:size="ButtonSizes.TEXT_M"
				>
					<CommonText :text="config.cancelButton!.text" />
				</CommonButton>
			</div>

			<!-- right buttons -->
			<div class="flex flex-row gap-2 pt-4">
				<!-- cancel button -->
				<CommonButton
					v-if="!isCancelOnLeft && config.cancelButton != undefined"
					:type="config.cancelButton!.buttonType || ButtonTypes.SECONDARY"
					@click="config.cancelButton!.effect"
					:size="ButtonSizes.TEXT_M"
					:data-testid="config.testIds?.cancel"
				>
					<CommonText :text="config.cancelButton!.text" />
				</CommonButton>

				<!-- regular buttons -->
				<CommonButton
					v-for="(button, index) in getGenericButtons"
					@click="button.effect"
					:type="getButtonType(index)"
					:size="ButtonSizes.TEXT_M"
					:disabled="button.disabled?.value as boolean"
					:data-id="`modal-regular-action-${index}`"
					:data-testid="config.testIds?.confirm"
				>
					<CommonText :text="button.text" />
				</CommonButton>

				<!-- danger button -->
				<CommonButton
					v-if="config.dangerButton != undefined"
					:type="ButtonTypes.DANGER"
					@click="config.dangerButton!.effect"
					:size="ButtonSizes.TEXT_M"
				>
					<CommonText :text="config.dangerButton!.text" />
				</CommonButton>
			</div>
		</div>
	</div>
</template>
