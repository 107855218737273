<script lang="ts" setup>
import { useNotificationsStore } from '~~/src/store/notifications';

const notificationsStore = useNotificationsStore()
const onClose = (index: number) => notificationsStore.closeNotificationAtIndex(index)
</script>

<template>
    <TransitionGroup name="notification-list" tag="div" class="fixed bottom-4 px-4 w-full flex flex-col-reverse gap-2 z-50">
        <CommonMessageCard v-for="(notification, index) in notificationsStore.notifications" :card-data="notification"
            :key="notification.creationHash" class="notification-list-item" @close="onClose(index)" />
    </TransitionGroup>
</template>

<style>
.notification-list-item {
    transition: all 200ms
}

.notification-list-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.notification-list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.notification-list-move {
    transition: transform 200ms
}
</style>