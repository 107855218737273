<script lang="ts" setup>
import { useClipboard } from '@vueuse/core';
import { PropType } from 'vue';
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys';
import { specialMessagesConfig } from '~~/src/config/SpecialMessagesConfig';

const props = defineProps({
    text: { type: String, required: true },
    onClickMessage: { type: String as PropType<TranslationKey>, default: TranslationKeys.CLIPBOARD_TEXT_SAVED }
})
const { text, onClickMessage } = toRefs(props)
const { copy } = useClipboard()

const showMessage = ref(false)
const onClick = () => {
    showMessage.value = true
    copy(text.value)

    setTimeout(() => showMessage.value = false, specialMessagesConfig.clipboardTextMessageDurationMs)
}
</script>

<template>
    <div class="w-full items-center justify-center relative bg-light-blue-100 border border-light-blue-200 rounded-md cursor-pointer"
        @click="onClick">
        <CommonTooltip :text="onClickMessage" :show="showMessage" class="w-full p-2 flex items-center justify-center">
            <span class="text-main-blue-700 active:text-white active:bg-main-blue-500">{{ text }}</span>
        </CommonTooltip>
    </div>
</template>
