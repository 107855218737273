<script lang="ts" setup>
import { PropType } from 'vue'
import { IdModal } from '~~/src/constants/IdModal'
import { useBasePricesStore } from '~~/src/store/basePrices'
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration'

const props = defineProps({
	config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
	modalId: { type: String as PropType<IdModal>, required: true },
})
const { config } = toRefs(props)
const basePricesStore = useBasePricesStore()

const confirmButtonDisabled = computed(() => !basePricesStore.isEditableBasePriceValid)

const configToForward = computed(() => {
	const { buttons } = config.value
	buttons![0].disabled = confirmButtonDisabled

	return {
		...config.value,
		buttons,
	}
})
</script>

<template>
	<ModalGeneric :modal-id="modalId" :config="configToForward" style="width: 1280px">
		<!-- needed otherwise the next titles are cut -->
		<div class="h-1" />
		<BasePricesCompactEditor />
	</ModalGeneric>
</template>
