<script lang="ts" setup>
import { ModifiersEditorGeneric, ModifiersEditorTweak } from '#components'
import { onKeyStroke } from '@vueuse/core'
import { lightFormat } from 'date-fns'
import { cloneDeep, isEqual } from 'lodash'
import { PropType, Ref } from 'vue'
import { TestIds } from '~/constants/TestIds'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { quickActionConfig } from '~~/src/config/QuickActionConfig'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { IconSizes } from '~~/src/constants/iconSizes'
import { TextSizes } from '~~/src/constants/textSizes'
import { TrackingMessages } from '~~/src/constants/trackingMessages'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useModifiersStore } from '~~/src/store/modifiers'
import { usePopupStore } from '~~/src/store/popup'
import { usePricesStore } from '~~/src/store/prices'
import { ModifierType, Modifiers, TweakModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'
import { utilModifiers } from '~~/src/utils/utilModifiers'
import { utilTracking } from '~~/src/utils/utilTracking'

const props = defineProps({
	modifier: { type: String as PropType<ModifierType>, required: true },
	date: { type: Date, required: true },
	modifierValue: { type: Number },
	tweakValue: { type: Object as PropType<TweakModifierType> },
	roomTypeId: { type: Number, required: true },
	otherModifiers: { type: Object as PropType<Modifiers>, required: true },
})
const { modifier, date, modifierValue, tweakValue, roomTypeId, otherModifiers } = toRefs(props)
const pricesStore = usePricesStore()
const modifiersStore = useModifiersStore()
const popupStore = usePopupStore()
const accommodationStore = useAccommodationsStore()
const currency = computed(() => accommodationStore.getRoomTypeCurrency(roomTypeId.value))
const newValue: Ref<any> = ref(
	modifier.value === ModifierType.Tweak ? cloneDeep(tweakValue?.value) : modifierValue?.value
)
watch(
	[modifierValue, tweakValue],
	() => (currentValue.value = modifier.value === ModifierType.Tweak ? tweakValue?.value : modifierValue?.value)
)

const editor = computed(() => (modifier.value === ModifierType.Tweak ? ModifiersEditorTweak : ModifiersEditorGeneric))
const currentValue = ref(modifierValue?.value || tweakValue?.value)
const onValueUpdate = (value: any) => (newValue.value = value)
const onSave = () => {
	const roomType = accommodationStore.getRoomTypeById(roomTypeId.value)
	const accommodation = accommodationStore.getAccommodationById(roomType?.accommodationId)

	pricesStore.updateModifiers(
		date.value,
		{ ...otherModifiers.value, [modifier.value]: newValue.value },
		roomTypeId.value
	)

	utilTracking.track(TrackingMessages.SINGLE_PRICE_CUSTOMIZATION, {
		changed_date: lightFormat(date.value, 'yyyy-MM-dd'),
		single_price_change_acc_country: accommodation?.location?.country,
		days_date_interval_num: 1,
		days_selected_num: 1,
		selected_rooms: roomTypeId.value,
		customization_source: 'Cell',
		customization_type: 'single_room',
		is_frozen_price: modifier.value === ModifierType.Frozen,
		is_max_price: modifier.value === ModifierType.Max,
		is_min_price: modifier.value === ModifierType.Min,
		is_tweak_price: modifier.value === ModifierType.Tweak,
		tweak_type:
			modifier.value === ModifierType.Tweak
				? utilModifiers.tweakToTrackingType(newValue.value as TweakModifierType)
				: '',
	})

	popupStore.closeTopPopup()
}

const trashEnabled = ref(false)
const onTrash = () => (trashEnabled.value = true)
const onCancelTrash = () => (trashEnabled.value = false)
const onConfirmTrash = () => {
	modifiersStore.bulkRemoveModifier(
		{ from: date.value, to: date.value },
		[date.value.getDay()],
		[roomTypeId.value],
		modifier.value
	)
	popupStore.closeTopPopup()
}
const onReset = () => {
	newValue.value = undefined
}

const borderColor = computed(() => quickActionConfig[modifier.value].borderDivide)
const disableSubmission = computed(() => {
	if (modifier.value === ModifierType.Tweak) {
		return (tweakValue?.value?.variationValue || 0) === (newValue?.value?.variationValue || 0)
	}
	return (modifierValue?.value || 0) === (newValue.value || 0) || newValue.value < 0
})

const clonedModifier = computed(() => ({
	...otherModifiers.value,
	[modifier.value]: newValue?.value,
}))
const modifiersErrors = computed(() => utilModifiers.getErrors(clonedModifier.value))
const modifiersHaveErrors = computed(() => utilModifiers.hasErrors(clonedModifier.value))

// close on escape key press
onKeyStroke('Escape', (e) => {
	e.preventDefault()
	popupStore.closeTopPopup()
})

const hasValueChanged = computed(() => isEqual(currentValue?.value, newValue.value))
const canShowTrashButton = computed(() => modifierValue?.value !== undefined && hasValueChanged.value)

// TODO: must clean this component
</script>

<template>
	<div
		class="flex w-64 flex-col"
		v-if="!trashEnabled"
		data-id="quick-action-popup"
		:data-testid="TestIds.CalendarQuickactionPopupContainer"
	>
		<div class="flex flex-col items-end gap-2 border-b p-4" :class="borderColor">
			<component
				class="w-full"
				:is="editor"
				:modifier-type="modifier"
				@change="onValueUpdate"
				:original-value="currentValue"
				:current-value="newValue"
				@delete="onReset"
				:currency="currency"
				:has-error="modifiersHaveErrors"
				:testIds="{
					'tweak-left-button': TestIds.CalendarQuickactionPopupToggle1,
					'tweak-right-button': TestIds.CalendarQuickactionPopupToggle2,
				}"
			/>
			<CommonText
				v-if="modifiersErrors[modifier] != undefined"
				:text="modifiersErrors[modifier]!"
				class="text-red-500"
				:text-size="TextSizes.PRODUCTIVE"
			/>
		</div>
		<div class="flex justify-between p-4">
			<CommonButton
				:type="ButtonTypes.SECONDARY"
				:size="ButtonSizes.ICON_M"
				@click="popupStore.closeTopPopup()"
				:data-testid="TestIds.CalendarQuickactionPopupXButton"
			>
				<CommonIcon :icon-name="SpSvg.BasicTimes" fill="fill-white" :icon-size="IconSizes.M" />
			</CommonButton>
			<CommonButton
				v-if="canShowTrashButton"
				:type="ButtonTypes.DANGER"
				:size="ButtonSizes.ICON_M"
				@click="onTrash"
				:data-testid="TestIds.CalendarQuickactionPopupDelButton"
			>
				<CommonIcon :icon-name="SpSvg.BasicTrash" fill="fill-white" :icon-size="IconSizes.M" />
			</CommonButton>
			<CommonButton
				v-else
				:type="ButtonTypes.PRIMARY"
				:size="ButtonSizes.ICON_M"
				data-id="quick-action-confirm"
				:disabled="disableSubmission || modifiersHaveErrors"
				@click="onSave"
				:data-testid="TestIds.CalendarQuickactionPopupVButton"
			>
				<CommonIcon :icon-name="SpSvg.BasicCheck" fill="fill-white" :icon-size="IconSizes.M" />
			</CommonButton>
		</div>
	</div>
	<div v-else class="flex w-64 flex-col">
		<div class="border-b p-4">
			<CommonText :text="TranslationKeys.MODIFIER_REMOVE_CONFIRMATION" />
		</div>
		<div class="flex justify-between p-4">
			<CommonButton
				:type="ButtonTypes.SECONDARY"
				:size="ButtonSizes.ICON_M"
				@click="onCancelTrash"
				:data-testid="TestIds.CalendarQuickactionPopupXButton"
			>
				<CommonIcon :icon-name="SpSvg.BasicTimes" fill="fill-white" :icon-size="IconSizes.M" />
			</CommonButton>
			<CommonButton
				:type="ButtonTypes.PRIMARY"
				:size="ButtonSizes.ICON_M"
				@click="onConfirmTrash"
				:data-testid="TestIds.CalendarQuickactionPopupVButton"
			>
				<CommonIcon :icon-name="SpSvg.BasicCheck" fill="fill-white" :icon-size="IconSizes.M" />
			</CommonButton>
		</div>
	</div>
</template>
