<script setup lang="ts">
import { PropType, ref } from 'vue';
import { DateImpact, EventType } from '~~/src/submodules/sharedTypes/common/Event';
import { utilNumber } from '~~/src/utils/UtilNumber';
import { addDays } from 'date-fns';
import { TranslationKey } from '~/i18n/TranslationKeys';

const props = defineProps({
    impacts: { type: Object as PropType<DateImpact[]>, required: true },
    eventType: { type: String as PropType<EventType>, required: true }
})

interface IimpactIndicator {
    startDate: Date,
    numberOfDays: number,
    weight: number,
    readableWeight: String
}

const { impacts, eventType } = toRefs(props)
const detailedImpacts = computed(() => {
    let result: IimpactIndicator[] = []

    impacts.value
        .sort((a, b) => a.date.getTime() - b.date.getTime())
        .forEach(el => {
            if (result.length > 0 && result[result.length - 1].weight == el.impact) {
                result[result.length - 1].numberOfDays++
            } else {
                result.push({
                    numberOfDays: 1,
                    startDate: el.date,
                    weight: el.impact,
                    readableWeight: utilNumber.toPercentage(el.impact)
                })
            }
        })

    return result
})

const hiddenText = ref(false)
const setHide = (value: boolean) => hiddenText.value = value

const hovering = ref(-1)
const hovered = (index: number) => hovering.value = index

const currentLocale = useLocale().currentLocale
const readableStartDate = computed(() => {
    return utilDate.formatYearlessShortLocale(detailedImpacts.value[0].startDate, currentLocale.value)
})
const readableEndDate = computed(() => {
    const el = detailedImpacts.value[detailedImpacts.value.length - 1]
    return utilDate.formatYearlessShortLocale(addDays(el.startDate, el.numberOfDays - 1), currentLocale.value)
})
</script>

<template>
    <div class="flex flex-col w-full">
        <div class="flex flex-row">
            <div v-for="(el, index) in detailedImpacts" class="flex flex-col w-full gap-2 truncate"
                :style="`flex: ${el.numberOfDays}`">

                <!-- visual indicator -->
                <EventsIndicator 
                    :index="index" :el="el" 
                    :detailed-impacts="detailedImpacts" 
                    :event-type="eventType" 
                    :hidden-text="hiddenText" 
                    :hovering="hovering"
                    :is-last="index === detailedImpacts.length - 1"
                    @hide-text="setHide"
                    @is-hovered="hovered" 
                />
            </div>
        </div>
        <div v-if="hiddenText" class="flex flex-row justify-between w-full pb-1 truncate">
            <CommonText
                :text="(readableStartDate as TranslationKey)"
            />
            <CommonText
                :text="(readableEndDate as TranslationKey)"
            />
        </div>
    </div>

</template>