<script lang="ts" setup>
import { PropType } from 'vue'
import { BulkUpdateModes } from '~~/src/constants/bulkUpdateModes'
import { EmitsEnum } from '~~/src/constants/emits'
import { DateRange } from '~~/src/submodules/sharedTypes/common/DateRange'
import { Modifiers, ModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'
import { useAccommodationsStore } from '../../../store/accommodations'

const props = defineProps({
	dateRange: { type: Object as PropType<DateRange>, required: true },
	bulkUpdateMode: { type: String, default: BulkUpdateModes.SINGLE },
	allowEdit: { type: Boolean, default: true },
	testIds: {
		type: Object as PropType<{
			'accommodation-dropdown'?: string
			'search-input'?: string
			'room-type-list'?: string
			'select-all'?: string
			'tweak-left-button'?: string
			'tweak-right-button'?: string
			'remover-modifier-container'?: string
			'filters-container'?: string
		}>,
		default: () => ({}),
	},
})
const { dateRange, bulkUpdateMode, allowEdit, testIds } = toRefs(props)
const emit = defineEmits([EmitsEnum.Change, EmitsEnum.Cancel, EmitsEnum.ErrorStatusChange])
const selectedAccommodationId = ref(useAccommodationsStore().accommodations[0].id)

const onCancel = (idList: number[], modifier: ModifierType) => emit(EmitsEnum.Cancel, idList, modifier)
const onChange = (modifiers: Map<number, Modifiers>, ids: number[]) => emit(EmitsEnum.Change, modifiers, ids)
const onError = (hasError: boolean) => emit(EmitsEnum.ErrorStatusChange, hasError)
const onAccommodationIdChange = (accommodationId: number) => (selectedAccommodationId.value = accommodationId)
</script>

<template>
	<div class="flex flex-col gap-4">
		<div v-if="bulkUpdateMode === BulkUpdateModes.SINGLE">
			<ModifiersBulkUpdateSingleMode
				:date-range="dateRange"
				:selected-accommodation-id="selectedAccommodationId"
				@cancel="onCancel"
				@change="onChange"
				@accommodation-id-change="onAccommodationIdChange"
				@error-status-change="onError"
				:allow-edit="allowEdit"
				:test-ids="testIds"
			/>
		</div>
		<ModifiersBulkUpdateMultipleMode
			v-else
			:date-range="dateRange"
			:selected-accommodation-id="selectedAccommodationId"
			@change="onChange"
			@accommodation-id-change="onAccommodationIdChange"
			@cancel="onCancel"
			:allow-edit="allowEdit"
			:test-ids="testIds"
		/>
	</div>
</template>
