import { JustifiersTooltipPopup, ModifiersQuickActionPopup } from '#components'
import { IdPopup } from '../constants/IdPopup'

export interface IPopupMapEntry {
    popup: any
}

export function getPopupById(popup: IdPopup) {
    const popupMap: Map<IdPopup, any> = new Map<IdPopup, IPopupMapEntry>([
        [IdPopup.ModifierQuickAction, { popup: ModifiersQuickActionPopup }],
        [IdPopup.JustifiersPopup, { popup: JustifiersTooltipPopup }],
    ])

    return popupMap.get(popup)
}
