<script lang="ts" setup>
import { PropType } from 'vue'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { IdModal } from '~~/src/constants/IdModal'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useEventsStore } from '~~/src/store/events'
import { useModalStore } from '~~/src/store/modals'
import { useModifiersStore } from '~~/src/store/modifiers'
import { eventsCloseTracking, eventsGoToImpactsViewTracking, EventStep } from '~~/src/tracking/eventsTracking'
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration'

const props = defineProps({
	config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
	modalId: { type: String as PropType<IdModal>, required: true },
})
const { config } = toRefs(props)
const eventStore = useEventsStore()
const modalStore = useModalStore()
const modifiersStore = useModifiersStore()
const accommodationStore = useAccommodationsStore()

const currentStep = ref(0)
const steps = [TranslationKeys.SETTINGS, TranslationKeys.IMPACT]
const canAccessSecondStep = computed(() => {
	// @ts-ignore
	const isLabelValid =
		eventStore.editableEvent.label[useLocale().currentLocale.value] != undefined &&
		// @ts-ignore
		eventStore.editableEvent.label[useLocale().currentLocale.value].trim() != ''

	const hasDates = eventStore.editableEventDetails.eventDates != undefined
	const hasAccommodations = eventStore.selectedAccommodations.length > 0

	return isLabelValid && hasDates && hasAccommodations
})
const canSave = computed(() => eventStore.editableEventDetails.impacts.length > 0)

const onNext = () => {
	currentStep.value += 1

	if (eventStore.editableEventDetails.impacts.length < 1) {
		eventStore.resetPreviews()
	} else {
		eventStore.requestPreview()

		const affectedDates = eventStore.editableEventDetails.impacts
			.map((el) => el.date)
			.sort((d1, d2) => d1.getTime() - d2.getTime())

		const roomTypeIds = eventStore.selectedAccommodations
			.map((el) => accommodationStore.getRoomTypesByAccommodationId(el).map((el) => el.id))
			.reduce((prev, next) => prev.concat(next), [])
		modifiersStore.requestForRange({ from: affectedDates[0], to: affectedDates[affectedDates.length - 1] }, roomTypeIds)
	}

	eventsGoToImpactsViewTracking(eventStore.editableEvent, eventStore.selectedAccommodations.length)
}

const onSave = () => {
	const modifiersAvailable = modifiersStore.modifiers.some((el) => el.enabledModifiers.length > 0)

	if (modifiersAvailable) {
		modalStore.addModal(IdModal.EventSave)
	} else {
		modalStore.closeModal(IdModal.AddEvent)
		eventStore.saveEvent(false)
	}
}

const onClose = () => {
	if (currentStep.value == 0) {
		eventsCloseTracking(eventStore.editableEvent, EventStep.SETTINGS_VIEW)
	} else if (currentStep.value == 1) {
		eventsCloseTracking(eventStore.editableEvent, EventStep.IMPACT_VIEW)
	}
}

const parentAccommodationName = computed(() => {
	const parentAccommodation = eventStore.editableEvent.derivedEventAccommodationId
		? useAccommodationsStore().getAccommodationById(eventStore.editableEvent.derivedEventAccommodationId)
		: undefined
	return parentAccommodation ? parentAccommodation.name : undefined
})

const createEventOnParent = () => {
	// closes current modal with event of the parent strategy
	useModalStore().closeTopModal()
	onClose()

	// new event
	eventStore.editableEvent.isDrivedEvent = false
	eventStore.eventInteractionAccommodationId = eventStore.editableEvent.derivedEventAccommodationId!
	eventStore.selectedAccommodations = [eventStore.editableEvent.derivedEventAccommodationId!]
	eventStore.editableEvent.derivedEventAccommodationId = undefined
	modalStore.addModal(IdModal.AddEvent)
}

const configToForward = computed(() => ({
	...config.value,
	cancelButton: !eventStore.editableEvent.isDrivedEvent
		? {
				effect: () => useModalStore().closeModal(IdModal.AddEvent),
				text: TranslationKeys.CANCEL,
			}
		: {
				effect: () => useModalStore().closeModal(IdModal.AddEvent),
				text: TranslationKeys.CLOSE,
			},
	onClose,
	title: {
		key: eventStore.editableEvent.id == undefined ? TranslationKeys.CREATE_NEW_EVENT : TranslationKeys.EDIT_EVENT,
	},
	buttons: !eventStore.editableEvent.isDrivedEvent
		? [
				{
					effect: () => (currentStep.value = Math.max(currentStep.value - 1, 0)),
					text: TranslationKeys.PREVIOUS,
					disabled: ref(currentStep.value == 0),
				},
				{
					effect: currentStep.value == 0 ? onNext : onSave,
					text: currentStep.value == 0 ? TranslationKeys.NEXT : TranslationKeys.CONFIRM,
					disabled: ref(
						(currentStep.value == 0 && !canAccessSecondStep.value) || (currentStep.value == 1 && !canSave.value)
					),
				},
			]
		: [],
}))
</script>

<template>
	<ModalGeneric
		:modal-id="modalId"
		:config="configToForward"
		:style="currentStep == 0 ? 'width: 600px' : 'width: 1280px'"
		data-id="new-event-dialog"
	>
		<template v-slot:default>
			<CommonStepper :step-names="steps" :current-step="currentStep" v-if="!eventStore.editableEvent.isDrivedEvent">
				<template v-slot:step-0>
					<EventsCompactSettingsEditor />
				</template>
				<template v-slot:step-1>
					<EventsCompactWeightsEditor />
				</template>
			</CommonStepper>
			<div v-else>
				<SpNotification
					class="w-full max-w-max"
					:dismissable="false"
					:type="SpNotificationType.Warning"
					:variant="SpNotificationVariant.Inline"
					:title="$t(TranslationKeys['calendar.events.derived-strategy-event-warning-title'])"
				>
					<template #body>
						<SpText :size="SpTextSize.BodyRegular01" class="text-dark-blue-900">
							{{ $t(TranslationKeys['calendar.events.derived-strategy-event-warning-text']) }}
							<SpButton :type="SpButtonType.Link" class="inline-flex" @click="createEventOnParent">
								{{ parentAccommodationName }}↗
							</SpButton>
						</SpText>
					</template>
				</SpNotification>
			</div>
		</template>
	</ModalGeneric>
</template>
