<script setup lang="ts">
import { isSameDay } from 'date-fns';
import { TranslationKeys } from '~/i18n/TranslationKeys';
import { SpSvg } from '~~/src/autogen/SpSvg';
import { eventWeightExponentialBase } from '~~/src/config/eventsWeights';
import { ButtonSizes } from '~~/src/constants/buttonSizes';
import { ButtonTypes } from '~~/src/constants/buttonTypes';
import { EmitsEnum } from '~~/src/constants/emits';
import { useEventsStore } from '~~/src/store/events';
import { DateImpact, EventType } from '~~/src/submodules/sharedTypes/common/Event';
import { eventsImpactFieldChange } from '~~/src/tracking/eventsTracking';
import { IMessageWithCondition } from '~~/src/types/IMessageWithCondition';

const props = defineProps({
    selectedDate: { type: Date, required: true },
})
const { selectedDate } = toRefs(props)
const emit = defineEmits([EmitsEnum.Delete, EmitsEnum.Change])

const eventsStore = useEventsStore()
const eventType = computed(() => eventsStore.editableEvent.eventType)
const gradientFrom = computed(() => eventType.value === EventType.Event ? 'from-events-50' : 'from-festivities-50')
const gradientTo = computed(() => eventType.value === EventType.Event ? 'to-events-600' : 'to-festivities-600')
const dateViewerBackgroundColor = computed(() => eventType.value === EventType.Event ? 'bg-events-100' : 'bg-festivities-100')
const dateViewerTextColor = computed(() => eventType.value === EventType.Event ? 'text-events-700' : 'text-festivities-600')
const currentWeight = computed(() => (Math.round(getCurrentWeightAmount() * 100)).toString())

// this variable is used to check if impacts has changed after a user interaction
let areImpactsChangedAfterInteraction = false

// TODO: use clamp
const onPercentageTextChange = (val: number) => applyWeight(Math.max(0, Math.min(val, 500)))
const onWeightConfirmed = (origin: string) => {
    eventsImpactFieldChange(eventsStore.editableEvent, origin)

    if (areImpactsChangedAfterInteraction) {
        areImpactsChangedAfterInteraction = false
        emit(EmitsEnum.Change)
    }
}

//TODO: should move to numberInput
// wether the number is convertible and above 0
const customInputError: IMessageWithCondition<string> = {
    condition: (message: string) => {
        const finalText = message.replace('%', ' ').trim()
        try {
            const val = Math.round(parseInt(finalText))
            if (val < 0) {
                throw Error('date weight too low')
            }
            return false
        } catch (e) {
            return true
        }
    },
    message: TranslationKeys.INVALID_VALUE
}

const applySliderWeight = (val: number) => {
    if (val > 100) {
        // the first 100 are applied flat,
        // afterwards the increment is exponential
        val = 100 + Math.round(Math.pow(eventWeightExponentialBase, val - 100))
    }

    applyWeight(val)
}

const applyWeight = (val: number) => {
    if (eventsStore.editableEventDetails.impacts == undefined) {
        eventsStore.editableEventDetails.impacts = []
    }

    const impacts: DateImpact[] = eventsStore.editableEventDetails.impacts
    const hasImpact = impacts.some((el) => isSameDay(selectedDate.value, el.date))
    if (!hasImpact) {
        impacts.push({ date: selectedDate.value, impact: val / 100 })
        impacts.sort((a, b) => (a.date.getUTCDate() - b.date.getUTCDate()))
        areImpactsChangedAfterInteraction = true
    } else {
        const oldImpact = impacts.find(el => isSameDay(selectedDate.value, el.date))!.impact
        const newImpact = val / 100

        impacts.find(el => isSameDay(selectedDate.value, el.date))!.impact = val / 100

        if (oldImpact !== newImpact) {
            areImpactsChangedAfterInteraction = true
        }
    }
}

const getCurrentWeightAmount = () => ((eventsStore.editableEventDetails.impacts || [])
    .find(el => isSameDay(selectedDate.value, el.date))?.impact || 0)
const alteredWeightHelperText = computed(() => eventsStore.getEditableEventHasAlteredWeight ? TranslationKeys.IMPACT_FIELD_HELPER_TEXT : undefined)

const currentWeightSteps = computed(() => {
    const currentWeightAmount = getCurrentWeightAmount() * 100
    if (currentWeightAmount <= 100) {
        return currentWeightAmount
    }
    const amount = Math.log(currentWeightAmount - 100) / Math.log(eventWeightExponentialBase)

    return 100 + Math.min(100, Math.round(amount))
})

const onWeightRemove = () => emit(EmitsEnum.Delete)
</script>

<template>
    <div class="flex flex-col gap-8 ">
        <div class="flex gap-2">
            <DateViewer :date="selectedDate" :background-color="dateViewerBackgroundColor"
                :text-color="dateViewerTextColor" />
            <CommonIconButton :icon="SpSvg.BasicTrash" :type="ButtonTypes.DANGER" :tooltip="TranslationKeys.REMOVE_DATE"
                @click="onWeightRemove" :size="ButtonSizes.ICON_M" />
        </div>
        <CommonNumberInput :label="TranslationKeys.IMPACT" is-percentage :value="currentWeight" @change="onPercentageTextChange"
            :error-condition="customInputError" @focus-out="onWeightConfirmed('Field')"
            :helper-text="alteredWeightHelperText" />
        <CommonSlider class="w-full" :start-value="currentWeightSteps" :max="200" :gradient-from="gradientFrom"
            :gradient-to="gradientTo" @change="applySliderWeight" @click="onWeightConfirmed('Slider')" />
    </div>
</template>