<script lang="ts" setup>
import { useElementBounding } from '@vueuse/core'
import { FunctionalIds } from '~~/src/constants/funcitonalIds'

const mainComponent = ref()
const { height } = useElementBounding(mainComponent)
</script>

<template>
	<div :id="FunctionalIds.BottomControlBarContainer" class="fixed bottom-0 z-20 w-full" ref="mainComponent"></div>
	<div class="w-full" :style="`height: ${height}px;`"></div>
</template>
