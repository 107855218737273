<script lang="ts" setup>
import { BasePricesModifier } from '#components'
import { PropType, Ref } from 'vue'
import { ISelectable } from '~/types/Selectable'
import { IdModal } from '~~/src/constants/IdModal'
import { UserflowId } from '~~/src/constants/UserflowId'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useBasePricesStore } from '~~/src/store/basePrices'
import { useModalStore } from '~~/src/store/modals'
import { Modifiers, TweakModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'
import { getModalById } from '~~/src/config/ModalsMap'
import { BasePrice } from '~/submodules/sharedTypes/common/BasePrice'
import { cloneDeep } from 'lodash'
import { TrackingMessages } from '~/constants/trackingMessages'
import { CardTypes } from '~/constants/cardTypes'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'

// TODO: clean this component

const props = defineProps({
	config: {
		// type: Object as PropType<IGenericModalConfiguration>,
		required: true,
	},
	modalId: { type: String as PropType<IdModal>, required: true },
})

const { config, modalId } = toRefs(props)
const modalStore = useModalStore()
const basePricesStore = useBasePricesStore()

const accommodationStore = useAccommodationsStore()
const accommodationId = basePricesStore.selectedAccommodationId
const roomTypeId = basePricesStore.selectedRoomTypeId
const accommodation = computed(() => accommodationStore.getAccommodationById(accommodationId))

const roomTypes = computed(() => {
	const rt =
		(accommodationStore.basePricesRoomTypesEnabled(accommodationId)?.map((rt) => ({
			id: rt.id,
			element: rt.name,
			selected: false,
			hidden: false,
			disabled: false,
		})) as ISelectable<TranslationKey, number>[]) ?? ([] as ISelectable<TranslationKey, number>[])
	return rt
})

const updateTypes = [TranslationKeys.PERIODS, TranslationKeys.PERIODS_AND_PRICES]
const currentEditMode = ref(0)
const showConfirmModal = ref(false)
watch(currentEditMode, () => {
	currentModifiers.value = {}
	currentSingleModeModifiers.value.clear()
	selectedRoomTypes.value = []
})
const updateFunctionToUse = () => {
	const sourceRoomTypeId = basePricesStore.selectedRoomTypeId
	const selectedRoomTypeIds = checkboxes.value
		.filter((el: ISelectable<TranslationKey, number>) => el.selected)
		.map((el: any) => el.roomTypeId)

	const selectedBasePrices = [...basePricesStore.selectedBasePrices]
	basePricesStore.setSelectedRoomTypeIds(selectedRoomTypeIds)
	if (currentEditMode.value === 1) {
		basePricesStore.setPriceModifiers(priceModifiers.value)
	}

	if (checkTargetPeriodBasePrices(selectedBasePrices, selectedRoomTypeIds)) {
		modalStore.addModal(IdModal.Overwrite)
	} else {
		duplicatePeriod(selectedRoomTypeIds, selectedBasePrices)
	}

	const uuid = crypto.randomUUID()
	selectedRoomTypeIds.forEach((roomId: number) => {
		const modifier = priceModifiers.value.get(roomId)
		let modifierTrackingData = {}
		if (modifier) {
			modifierTrackingData = {
				tweak_type: modifier?.variationType === 'amount' ? 'abs' : '%',
				tweak_value:
					modifier?.variationType === 'percentage' ? modifier?.variationValue * 100 : modifier?.variationValue,
			}
		}

		utilTracking.track(TrackingMessages.BASE_PRICE_COPY, {
			is_same_unit: roomId === sourceRoomTypeId,
			num_periods: selectedBasePrices.length,
			is_bulk: selectedRoomTypeIds.length > 1,
			uuid: uuid,
			copy_type: currentEditMode.value ? 'price_and_periods' : 'periods_only',
			...modifierTrackingData,
		})
	})

	modalStore.closeModal(modalId.value)
}

const checkTargetPeriodBasePrices = (basePrices: BasePrice[], roomTypeIds: number[]) => {
	let showOverwriteModal = false
	roomTypeIds.forEach((rtid: number) => {
		const bpRoomType = basePricesStore.getBasePricesByAccommodationAndRoomTypeId(accommodationId, rtid)
		bpRoomType.forEach((roomTypebp: BasePrice) => {
			basePrices.forEach((bp: BasePrice) => {
				if (
					(bp.dateRange.from <= roomTypebp.dateRange.to && bp.dateRange.from >= roomTypebp.dateRange.from) ||
					(bp.dateRange.to >= roomTypebp.dateRange.from && bp.dateRange.to <= roomTypebp.dateRange.to)
				)
					showOverwriteModal = true
			})
		})
	})
	return showOverwriteModal
}

const duplicatePeriod = (selectedRoomTypeIds: number[], selectedBasePrices: BasePrice[]) => {
	selectedBasePrices.forEach((bp: BasePrice) => {
		const clonedBp = cloneDeep(bp)
		selectedRoomTypeIds.forEach((selectedRoomTypeId: number) => {
			if (currentEditMode.value === 0) {
				clonedBp.price = null
			} else {
				const modifier = priceModifiers.value.get(selectedRoomTypeId)
				if (modifier && clonedBp.price) {
					clonedBp.price =
						modifier.variationType == 'amount'
							? clonedBp.price + modifier.variationValue
							: clonedBp.price + clonedBp.price * modifier.variationValue
				}
			}
			const editableBasePrice = {
				accommodationId,
				roomTypeId: selectedRoomTypeId,
				basePrice: clonedBp,
			}

			basePricesStore.addBasePrice(editableBasePrice)
			basePricesStore.setConfirmedAdd(!basePricesStore.confirmedAdd)
		})
	})
}
const onEditModeChange = (index: number) => (currentEditMode.value = index)

const selectedRoomTypes: Ref<number[]> = ref([])

const configToForward = computed(() => ({
	...config.value,
	buttons: [
		{
			...config.value.buttons![0]!,
			effect: updateFunctionToUse,
			disabled: realButtonDisabled,
		},
	],
}))

const currentModifiers: Ref<Modifiers> = ref({})
const currentSingleModeModifiers = ref(new Map<number, Modifiers>())

const checkboxes = computed((): any => {
	if (currentEditMode.value === 0) {
		return roomTypes.value
			.filter((room) => room.id !== roomTypeId)
			.map((el, index) => {
				return {
					id: index,
					element: el.element as TranslationKey,
					selected: el.selected,
					disabled: el.disabled,
					roomTypeId: el.id,
				}
			})
	} else {
		return roomTypes.value
			.sort((a, b) => {
				if (a.id === roomTypeId) {
					return -1
				} else if (b.id === roomTypeId) {
					return 1
				} else {
					return 0
				}
			})
			.map((el, index) => {
				return {
					id: index,
					element: el.element as TranslationKey,
					selected: el.selected,
					disabled: el.disabled,
					roomTypeId: el.id,
				}
			})
	}
})
const realButtonDisabled = ref(true)

const disableButton = () => {
	realButtonDisabled.value =
		checkboxes.value.filter((el: ISelectable<TranslationKey, number>) => el.selected).length < 1
}

const priceModifiers = ref(new Map<number, TweakModifierType>())
const areThereNegativePrices = computed(() =>
	Array.from(roomTypesNegativePricesPreview.value).some(([_, value]) => value)
)
const roomTypesNegativePricesPreview = ref(new Map())
const updateValue = (value: any, rtid: number) => {
	// we need to prevent negative values as a result of the calculation.
	// So we just need to check for negative modifiers.
	if (
		value.variationValue < 0 &&
		value.variationType === 'amount' &&
		basePricesStore.selectedBasePrices.some(({ price }) => price - Math.abs(value.variationValue) <= 0)
	) {
		roomTypesNegativePricesPreview.value.set(rtid, true)
		realButtonDisabled.value = true
	} else if (
		(value.variationValue < 0 && value.variationType === 'percentage' && Math.abs(value.variationValue) >= 1) ||
		(value.variationValue < 0 &&
			value.variationType === 'percentage' &&
			basePricesStore.selectedBasePrices.some(
				({ price }) => Math.trunc(price - Math.abs(value.variationValue) * price) <= 0
			))
	) {
		roomTypesNegativePricesPreview.value.set(rtid, true)
		realButtonDisabled.value = true
	} else {
		roomTypesNegativePricesPreview.value.set(rtid, false)
		realButtonDisabled.value =
			checkboxes.value.filter((el: ISelectable<TranslationKey, number>) => el.selected).length < 1
	}

	priceModifiers.value.set(rtid, value)
}

const childComponent = () => (currentEditMode.value == 1 ? BasePricesModifier : null)
const childComponentProps = (value: any) => {
	const selectedRoomTypeId = checkboxes.value[value].roomTypeId
	return currentEditMode.value == 1
		? {
				onChange: updateValue,
				accommodation: accommodation.value,
				roomTypeId: selectedRoomTypeId,
			}
		: null
}

const duplicatingPeriodsAccordionTitle = computed(() =>
	!!currentEditMode.value
		? useLocale().translate(TranslationKeys.BASE_PRICES_DUPLICATED_PERIOD_AND_PRICES_MULTIPLE)
		: useLocale().translate(TranslationKeys.BASE_PRICES_DUPLICATED_PERIOD_MULTIPLE)
)
</script>

<template>
	<ModalGeneric :modal-id="modalId" :config="configToForward" style="width: 600px">
		<template v-slot:default>
			<div class="flex flex-col gap-6">
				<CommonRangeSelector
					:entries="updateTypes"
					:selected-option="currentEditMode"
					@click="onEditModeChange"
					:data-id="UserflowId.BulkUpdateTypeSelector"
				/>

				<SpAccordion v-if="basePricesStore.selectedBasePrices.length > 1">
					<SpAccordionItem id="duplicate-period" :title="duplicatingPeriodsAccordionTitle">
						<ul class="mt-1 flex flex-col gap-1">
							<li
								v-for="(basePrice, index) in basePricesStore.selectedBasePrices"
								:key="index"
								class="flex items-center justify-start gap-8 whitespace-normal text-sm text-dark-blue-500"
							>
								<span class="inline-block min-w-[150px]">{{ utilDate.formatDateRange(basePrice.dateRange) }}</span>

								<span v-if="currentEditMode === 1">
									{{ basePrice.price }}{{ utilCurrency.currencySymbol(accommodation?.currency || { code: 'EUR' }) }}
								</span>
							</li>
						</ul>
					</SpAccordionItem>
				</SpAccordion>

				<div
					class="border-1 flex items-center justify-between gap-8 whitespace-normal rounded-md border-dark-blue-100 bg-dark-blue-100 px-4 py-2 text-sm text-dark-blue-500"
					v-else
				>
					<CommonText
						:text="
							currentEditMode === 0
								? TranslationKeys.BASE_PRICES_DUPLICATED_PERIOD
								: TranslationKeys.BASE_PRICES_DUPLICATED_PERIOD_AND_PRICE
						"
					/>

					<span>{{ utilDate.formatDateRange(basePricesStore.selectedBasePrices[0].dateRange) }}</span>

					<span v-if="currentEditMode === 1"
						>{{ basePricesStore.selectedBasePrices[0]?.price
						}}{{ utilCurrency.currencySymbol(accommodation?.currency || { code: 'EUR' }) }}</span
					>
				</div>

				<CommonText :text="accommodation!.name as TranslationKey" />

				<AnimationCollapse>
					<div
						v-if="areThereNegativePrices"
						class="flex items-center gap-3 whitespace-normal bg-green-400"
						:class="useMessageCardColors(CardTypes.WARNING).mainColor"
					>
						<CommonText :text="TranslationKeys.BASE_PRICES_DUPLICATION_NEGATIVE_VALUES" class="m-3" />
					</div>
				</AnimationCollapse>

				<!-- TODO:  #AskZanna
                    2. Dopo il click su duplica mi apporta le modifiche anche alla roomType di partenza, tip: check cloneDeep
                    3. Altezza chebox dev'essere 36px
                -->
				<CommonCheckboxList
					v-if="checkboxes.length"
					:data-id="accommodation?.name + ''"
					:items="checkboxes"
					:get-child="childComponent"
					:get-child-props="childComponentProps"
					:row-padding-y="'py-1'"
					:inner-background-color="'bg-dark-blue-50'"
					@change="disableButton"
					checkbox-clickable-area-classes="w-9 h-9"
					:translate-checkboxes="false"
				/>
				<ModalGeneric v-if="showConfirmModal" :modal-id="IdModal.Overwrite" :config="getModalById(IdModal.Overwrite)" />
			</div>
		</template>
	</ModalGeneric>
</template>
