export function useCalendarConversion<T>(
    data: T,
    getFields: (data: T) => { start: Date; end?: Date } = (data: T) => ({
        // @ts-ignore
        start: data.from,
        // @ts-ignore
        end: data.to,
    }),
    writeFields: (start: Date, end?: Date) => void = (
        start: Date,
        end?: Date
    ) => {
        // @ts-ignore
        data.from = start
        // @ts-ignore
        data.to = end
    }
) {
    // format data for v-calendar
    const initialData = getFields(data)
    const connectedVModel = reactive({
        start: initialData.start,
        end: initialData.end,
    })

    watch(
        () => data,
        () => {
            const { start, end } = getFields(data)
            connectedVModel.start = start
            connectedVModel.end = end
        }
    )

    const onVmodelUpdate = (vCalendarUpdate: { start: Date; end?: Date }) => {
        connectedVModel.start = vCalendarUpdate.start
        connectedVModel.end = vCalendarUpdate.end
        writeFields(connectedVModel.start, connectedVModel.end)
    }

    return {
        connectedVModel,
        onVmodelUpdate,
    }
}
