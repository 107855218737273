<script lang="ts" setup>
import { useNotificationsStore } from '~~/src/store/notifications'

const notificationsStore = useNotificationsStore()
const onClose = (index: number) => notificationsStore.closeNotificationAtIndex(index)
</script>

<template>
	<TransitionGroup name="notification-list" tag="div" class="fixed right-4 top-4 z-50 flex w-80 flex-col gap-2">
		<CommonMessageCard
			v-for="(notification, index) in notificationsStore.notifications"
			:card-data="notification"
			:key="notification.creationHash"
			class="notification-list-item"
			@close="onClose"
			:data-testid="notification.testId"
		/>
	</TransitionGroup>
</template>

<style>
.notification-list-item {
	transition: all 1s;
}

.notification-list-leave-to,
.notification-list-enter-from {
	opacity: 0;
	transform: translateX(30px);
}

.notification-list-move {
	transition: transform 1s;
}
</style>
