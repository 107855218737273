import {
	ModalBasePricesCreator,
	ModalCalendarBulkUpdate,
	ModalEmailField,
	ModalEventDetails,
	ModalEventRemoveModifiers,
	ModalEventSave,
	ModalEventsCreator,
	ModalFeedback,
	ModalGeneric,
	ModalMobile,
	ModalPriceCalculator,
	ModalPriceJustifiersHelp,
	ModalStrategySave,
	ModalTextField,
	ModalDuplicateBasePrice,
	ModalOverwrite,
} from '#components'
import { Component } from '@vue/runtime-dom'
import { utilTracking } from '~/utils/utilTracking'
import { IdModal } from '../constants/IdModal'
import { TrackingMessages } from '../constants/trackingMessages'
import { useBasePricesStore } from '../store/basePrices'
import { useAccommodationsStore } from './../store/accommodations'
import { useClosingDatesStore } from './../store/closingDates'
import { useEventsStore } from './../store/events'
import { useModalStore } from './../store/modals'
import { useModifiersStore } from './../store/modifiers'
import { useStrategiesStore } from './../store/strategies'
import { PasswordRecoveryNetworkObject } from './../submodules/sharedTypes/communication/auth/password-recovery/PasswordRecoveryNetworkObject'
import { eventsGoToSettingsTracking } from './../tracking/eventsTracking'
import { IGenericModalConfiguration } from './../types/IGenericModalConfiguration'
import { utilNetwork } from './../utils/UtilNetwork'
import { utilDate } from './../utils/utilDate'
import { utilHelpDesk } from '../utils/utilHelpDesk'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { TestIds } from '~/constants/TestIds'

export interface IModalMapEntry {
	modal: Component
	props: IGenericModalConfiguration
}

export function getModalById(modal: IdModal) {
	const modalsMap: Map<IdModal, any> = new Map<IdModal, IModalMapEntry>([
		[
			IdModal.Mobile,
			{
				modal: ModalMobile,
				props: {
					allowClose: true,
					/* handled in the modal */
					title: { key: TranslationKeys.ACCOMMODATION_CLOSURES },
				},
			},
		],
		[
			IdModal.PasswordForgotten,
			{
				modal: ModalEmailField,
				props: {
					testIds: {
						container: 'LoginForgotDialog',
						email: 'LoginForgotEmailField',
						closeIcon: 'LoginForgotX',
						cancel: 'LoginForgotCancel',
						confirm: 'LoginForgotReset',
					},
					title: { key: TranslationKeys.PASSWORD_FORGOTTEN },
					text: TranslationKeys.PASSWORD_FORGOTTEN_TEXT,
					allowClose: true,
					cancelButton: {
						text: TranslationKeys.CANCEL,
						effect: () => useModalStore().closeModal(IdModal.PasswordForgotten),
					},
					buttons: [
						{
							text: TranslationKeys.PASSWORD_RECOVERY,
							effect: (mail: string) => {
								const modalStore = useModalStore()
								utilNetwork.simpleNonAuthorizedRequest(
									new PasswordRecoveryNetworkObject({
										lang: useLocale().currentLocale.value,
										username: mail,
									}),
									() => {},
									() => {}
								)

								utilTracking.track(TrackingMessages.PASSWORD_RESET_REQUESTED, {})

								modalStore.closeModal(IdModal.PasswordForgotten)
								modalStore.addModal(IdModal.PasswordRecoverySent)
							},
						},
					],
					inputInfos: {
						label: { key: TranslationKeys.EMAIL_ADDRESS },
						placeholder: TranslationKeys.EMAIL,
						error: {
							condition: (message: string) => false,
							message: TranslationKeys.EMAIL_REPORT,
						},
					},
				},
			},
		],
		[
			IdModal.PasswordRecoverySent,
			{
				modal: ModalGeneric,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.PASSWORD_RECOVERY_ACTION },
					text: TranslationKeys.PASSWORD_RECOVERY_SEND_SUCCESS,
				},
			},
		],
		[
			IdModal.DeleteClosingPeriod,
			{
				modal: ModalGeneric,
				props: {
					title: { key: TranslationKeys.DELETE_CLOSURE },
					allowClose: false,
					text:
						useClosingDatesStore().getSelectedClosingPeriod == undefined
							? TranslationKeys.DELETE_CLOSING_PERIOD_TEXT
							: TranslationKeys.DELETE_ROOM_CLOSING_PERIOD_TEXT,
					textReplacements: () => {
						const closingDateStore = useClosingDatesStore()
						const name = closingDateStore.getSelectedClosingPeriodName
						const period = utilDate.formatDateRange({
							from: closingDateStore.getSelectedClosingPeriod?.from || new Date(),
							to: closingDateStore.getSelectedClosingPeriod?.to || new Date(),
						})

						return [name, period].map((entry) => `<span class="text-red-400">${entry}</span>`)
					},
					cancelButton: {
						text: TranslationKeys.CANCEL,
						effect: () => useModalStore().closeModal(IdModal.DeleteClosingPeriod),
					},
					dangerButton: {
						text: TranslationKeys.CONFIRM,
						effect: () => {
							useClosingDatesStore().removeClosingDateConfirmed()
							useModalStore().closeModal(IdModal.DeleteClosingPeriod)
						},
					},
				},
			},
		],
		[
			IdModal.BadCustomer,
			{
				modal: ModalGeneric,
				props: {
					allowClose: false,
					title: { key: TranslationKeys.ARE_YOUR_PAYMENT_DATA_UPDATED },
					text: TranslationKeys.ARE_YOUR_PAYMENT_DATA_UPDATED_TEXT,
					cancelButton: {
						text: TranslationKeys.CLOSE,
						effect: () => useModalStore().closeModal(IdModal.BadCustomer),
					},
					buttons: [
						{
							text: TranslationKeys.CONTACT_US,
							effect: () => utilHelpDesk.openChatbot(),
						},
					],
				},
			},
		],
		[
			IdModal.PriceCalculator,
			{
				modal: ModalPriceCalculator,
				props: {
					testIds: {
						cancel: TestIds.CalendarCalculatePopupCancelButton,
						confirm: TestIds.CalendarCalculatePopupConfirmButton,
					},
					allowClose: true,
					title: { key: TranslationKeys.CALCULATE_PRICE_MULTIPLE_DATES },
					cancelButton: {
						text: TranslationKeys.CANCEL,
						effect: () => {
							useModalStore().closeModal(IdModal.PriceCalculator)
						},
					},
					buttons: [
						{
							effect: () => {},
							text: TranslationKeys.CALCULATE,
						},
					],
				},
			},
		],
		[
			IdModal.CalendarBulkUpdate,
			{
				modal: ModalCalendarBulkUpdate,
				props: {
					testIds: {
						cancel: TestIds.CalendarCustomizePopupCancelButton,
						confirm: TestIds.CalendarCustomizePopupConfirmButton,
					},
					allowClose: true,
					title: { key: TranslationKeys.CUSTOMIZE_PRICES_MULTIPLE_DATES },
					cancelButton: {
						text: TranslationKeys.CANCEL,
						effect: () => useModalStore().closeModal(IdModal.CalendarBulkUpdate),
					},
					buttons: [
						{
							effect: () => {},
							text: TranslationKeys.CONFIRM,
						},
					],
				},
			},
		],
		[
			IdModal.PriceJustifiersHelp,
			{
				modal: ModalPriceJustifiersHelp,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.PRICES_JUSTIFIER },
					label: { key: TranslationKeys.INFO },
					text: TranslationKeys.MODIFIERS_HELP_TEXT,
				},
			},
		],
		[
			IdModal.PlaygroundInfo,
			{
				modal: ModalGeneric,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.SIMULATED_DATA },
					label: { key: TranslationKeys.PLAYGROUND },
					text: TranslationKeys.SIMULATED_DATA_EXPLAINATION,
					buttons: [
						{
							text: TranslationKeys.OK,
							effect: () => useModalStore().closeModal(IdModal.PlaygroundInfo),
						},
					],
				},
			},
		],
		[
			IdModal.ResetStrategies,
			{
				modal: ModalGeneric,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.CANCEL_ALL_STRATEGIES },
					text: TranslationKeys.CANCEL_ALL_STRATEGIES_TEXT,
					buttons: [
						{
							text: TranslationKeys.CANCEL,
							effect: () => useModalStore().closeModal(IdModal.ResetStrategies),
						},
						{
							text: TranslationKeys.CANCEL_STRATEGIES,
							effect: () => {
								useModalStore().closeModal(IdModal.ResetStrategies)
								useStrategiesStore().saveDefaultStrategy()
							},
						},
					],
				},
			},
		],
		[
			IdModal.SaveStrategyWithModifiers,
			{
				modal: ModalStrategySave,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.SAVE_STRATEGIES },
					label: { key: TranslationKeys.STRATEGIES },
					cancelButton: {
						text: TranslationKeys.CANCEL,
						effect: () => useModalStore().closeModal(IdModal.SaveStrategyWithModifiers),
					},
					buttons: [
						{
							text: TranslationKeys.SAVE_ANYWAY,
							effect: () => {
								useStrategiesStore().saveStrategy(false)
								useModalStore().closeModal(IdModal.SaveStrategyWithModifiers)
							},
						},
						{
							text: TranslationKeys.REMOVE_AND_SAVE,
							effect: () => {
								useStrategiesStore().saveStrategy(true)
								useModalStore().closeModal(IdModal.SaveStrategyWithModifiers)
							},
						},
					],
				},
			},
		],
		[
			IdModal.EventRemoveModifiers,
			{
				modal: ModalEventRemoveModifiers,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.REMOVE_MODIFIERS },
					text: TranslationKeys.REMOVE_EVENT_MODIFIERS_CONFIRMATION,
					cancelButton: {
						text: TranslationKeys.CANCEL,
						effect: () => {
							useModalStore().closeModal(IdModal.EventRemoveModifiers)
						},
					},
					buttons: [
						{
							text: TranslationKeys.REMOVE,
							effect: () => {
								const eventStore = useEventsStore()
								const accommodationStore = useAccommodationsStore()
								const roomTypes = eventStore.selectedAccommodations
									.map((el) => accommodationStore.getRoomTypesByAccommodationId(el).map((room) => room.id))

									.reduce((prev, next) => prev.concat(next), [])

								const modifiersStore = useModifiersStore()
								modifiersStore.bulkRemoveAllModifiers(modifiersStore.dateRange, [0, 1, 2, 3, 4, 5, 6], roomTypes)

								modifiersStore.modifiers = []
								eventStore.eventWithModifiersPreview = []

								useModalStore().closeModal(IdModal.EventRemoveModifiers)
							},
						},
					],
				},
			},
		],
		[
			IdModal.UserFeedback,
			{
				modal: ModalFeedback,
				props: {
					allowClose: true,
					// It is required, but it will be overridden
					title: { key: TranslationKeys.YEARS },
				},
			},
		],
		[
			IdModal.BulkUpdateModifiersConflict,
			{
				modal: ModalGeneric,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.INCOMPATIBLE_MODIFIERS },
					label: { key: TranslationKeys.CUSTOMIZE_PRICES },
					text: TranslationKeys.INCOMPATIBLE_MODIFIERS_TEXT,
					cancelButton: {
						text: TranslationKeys.CANCEL,
						effect: () => useModalStore().closeModal(IdModal.BulkUpdateModifiersConflict),
					},
					buttons: [
						{
							text: TranslationKeys.REMOVE_AND_SAVE,
							effect: () => {
								const modifiersStore = useModifiersStore()

								modifiersStore.bulkUpdateSingleRoomsModifiers(
									modifiersStore.dateRange[0],
									modifiersStore.dateFilter,
									modifiersStore.singleModifiersPopupContent
								)
								utilTracking.track(TrackingMessages.BULK_PRICE_CUSTOMIZATION, modifiersStore.trackingMessage)

								useModalStore().closeAllModals()
							},
						},
					],
				},
			},
		],
		[
			IdModal.AddEvent,
			{
				modal: ModalEventsCreator,
				props: {
					allowClose: true,
					title: {
						key: TranslationKeys.ACCOMMODATION_CLOSURES,
					} /* handled in the modal */,
					cancelButton: {
						effect: () => useModalStore().closeModal(IdModal.AddEvent),
						text: TranslationKeys.CANCEL,
					},
				},
			},
		],
		[
			IdModal.EventSave,
			{
				modal: ModalEventSave,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.EDIT_EVENT },
					cancelButton: {
						effect: () => useModalStore().closeModal(IdModal.EventSave),
						text: TranslationKeys.CANCEL,
					},
					buttons: [
						{
							text: TranslationKeys.SAVE_ANYWAY,
							effect: () => {
								useModalStore().closeModal(IdModal.AddEvent)
								useModalStore().closeModal(IdModal.EventSave)
								useModalStore().closeModal(IdModal.EventDetails)
								useEventsStore().saveEvent(false)
							},
						},
						{
							text: TranslationKeys.REMOVE_AND_SAVE,
							effect: () => {
								useModalStore().closeModal(IdModal.AddEvent)
								useModalStore().closeModal(IdModal.EventSave)
								useModalStore().closeModal(IdModal.EventDetails)
								useEventsStore().saveEvent(true)
							},
						},
					],
				},
			},
		],
		[
			IdModal.EventDetails,
			{
				modal: ModalEventDetails,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.ACCOMMODATIONS },
					buttons: [
						{
							text: TranslationKeys.CLOSE,
							effect: () => useModalStore().closeTopModal(),
						},
						{
							text: TranslationKeys.EDIT,
							effect: () => {
								const modalStore = useModalStore()

								modalStore.addModal(IdModal.AddEvent)
								eventsGoToSettingsTracking(useEventsStore().editableEvent)
								modalStore.closeModal(IdModal.EventDetails)
							},
						},
					],
				},
			},
		],
		[
			IdModal.DisabledEventDetails,
			{
				modal: ModalEventDetails,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.ACCOMMODATIONS },
					buttons: [
						{
							text: TranslationKeys.EDIT,
							effect: () => {
								const modalStore = useModalStore()

								modalStore.addModal(IdModal.AddEvent)
								eventsGoToSettingsTracking(useEventsStore().editableEvent)
								modalStore.closeModal(IdModal.DisabledEventDetails)
							},
						},
						{
							text: TranslationKeys.RESTORE,
							effect: () => {
								useEventsStore().toggleCurrentEventVisibility()
								useModalStore().closeModal(IdModal.DisabledEventDetails)
							},
						},
					],
				},
			},
		],
		[
			IdModal.AddBasePrice,
			{
				modal: ModalBasePricesCreator,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.BASE_PRICES_EDIT_PERIOD },
					cancelButton: {
						text: TranslationKeys.CANCEL,
						effect: () => {
							useBasePricesStore().resetEditableBasePrice()
							useBasePricesStore().requestEditablePreview()
							useBasePricesStore().setConfirmedAdd(false)
							useModalStore().closeModal(IdModal.AddBasePrice)
						},
					},
					buttons: [
						{
							text: TranslationKeys.CONFIRM,
							effect: () => {
								useBasePricesStore().setConfirmedAdd(true)
								useBasePricesStore().addBasePrice()
								useModalStore().closeModal(IdModal.AddBasePrice)
							},
						},
					],
				},
			},
		],
		[
			IdModal.DuplicateBasePrice,
			{
				modal: ModalDuplicateBasePrice,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.DUPLICATE_SELECTED_PERIODS },
					text: TranslationKeys.DUPLICATE_PERIODS_PRICES,
					cancelButton: {
						text: TranslationKeys.CANCEL,
						effect: () => useModalStore().closeModal(IdModal.DuplicateBasePrice),
					},
					buttons: [
						{
							text: TranslationKeys.DUPLICATE_BASE_PRICES_DIALOG_BUTTON,
							effect: () => {
								useModalStore().closeModal(IdModal.DuplicateBasePrice)
							},
						},
					],
				},
			},
		],
		[
			IdModal.Overwrite,
			{
				modal: ModalOverwrite,
				props: {
					allowClose: true,
					title: { key: TranslationKeys.OVERRIDE_PERIODS },
					label: { key: TranslationKeys.PRICE_LIST },
					text: TranslationKeys.BASE_PRICE_ALREADY_AVAILABLE,
					cancelButton: {
						text: TranslationKeys.CANCEL,
						effect: () => useModalStore().closeModal(IdModal.Overwrite),
					},
					buttons: [
						{
							text: TranslationKeys.OVERRIDE,
							effect: () => {
								useModalStore().closeModal(IdModal.DuplicateBasePrice)
							},
						},
					],
				},
			},
		],
		[
			IdModal.GenericConfirmation,
			{
				modal: ModalGeneric,
				props: {
					allowClose: false,
					title: { key: TranslationKeys.DELETE_BASE_PRICE_PERIOD },
					text: TranslationKeys.CONFIRM_DELETE_BASE_PRICE_PERIOD,
					cancelButton: {
						text: TranslationKeys.CANCEL,
						effect: () => {
							useBasePricesStore().setConfirmedDelete(false)
							useModalStore().closeModal(IdModal.GenericConfirmation)
						},
					},
					buttons: [
						{
							text: TranslationKeys.DELETE,
							effect: () => {
								useBasePricesStore().setConfirmedDelete(true)
								useModalStore().closeModal(IdModal.GenericConfirmation)
							},
						},
					],
				},
			},
		],
	])

	return modalsMap.get(modal)
}
