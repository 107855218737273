<script lang="ts" setup>
import { getModalById } from '~~/src/config/ModalsMap'
import { useBodyScrollLock } from '~~/src/hooks/useBodyScrollLock'
import { useModalStore } from '~~/src/store/modals'
import { useMobileInfos } from '../../hooks/useMobileInfos'
import { IdModal } from '../../constants/IdModal'

const DONT_LOCK_BODY_MODAL_IDS = [IdModal.PriceJustifiersHelp]

const modalStore = useModalStore()
const { isMobile } = useMobileInfos()
const displayModals = computed(() => modalStore.modals.length > 0)
const { setScrollLock } = useBodyScrollLock(displayModals.value)
const isHtmlLocked = useScrollLock(document.documentElement)
watch(displayModals, () => {
	if (!DONT_LOCK_BODY_MODAL_IDS.includes(modalStore.modals[0])) {
		setScrollLock(displayModals.value)
	} else {
		isHtmlLocked.value = displayModals.value
	}
})

const computedBackgroundStyles = computed(() => ({
	backdropFilter: isMobile.value && 'blur(3.5px)',
}))
</script>

<template>
	<div class="fixed left-[60px] top-0 z-[9999] h-0 w-full">
		<template v-for="modalId in modalStore.modals" :key="modalId">
			<div v-if="displayModals">
				<div class="absolute left-0 h-screen w-screen bg-dark-blue-700 opacity-60" />
				<div
					class="absolute flex h-screen w-screen items-center justify-center p-4"
					:style="computedBackgroundStyles"
					@click="() => modalStore.closeModal(modalId)"
				>
					<component
						:is="getModalById(modalId).modal"
						:config="getModalById(modalId).props"
						:modal-id="modalId"
						class="shadow-high-contrast"
						@click.stop="() => {}"
					/>
				</div>
			</div>
		</template>
	</div>
</template>
