<script lang="ts" setup>
import { ModifiersManager } from '#components'
import { PropType, Ref } from 'vue'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { EmitsEnum } from '~~/src/constants/emits'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useModifiersStore } from '~~/src/store/modifiers'
import { usePricesStore } from '~~/src/store/prices'
import { DateRange } from '~~/src/submodules/sharedTypes/common/DateRange'
import { Modifiers, ModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'

const props = defineProps({
	dateRange: { type: Object as PropType<DateRange>, required: true },
	selectedAccommodationId: Number,
	allowEdit: { type: Boolean, default: true },
	testIds: {
		type: Object as PropType<{
			'accommodation-dropdown'?: string
			'search-input'?: string
			'room-type-list'?: string
			'select-all'?: string
			'tweak-left-button'?: string
			'tweak-right-button'?: string
			'remover-modifier-container'?: string
			'filters-container'?: string
		}>,
		default: () => ({}),
	},
})
const { dateRange, selectedAccommodationId, allowEdit, testIds } = toRefs(props)

// stores and emit
const modifiersStore = useModifiersStore()
const pricesStore = usePricesStore()
const accommodationStore = useAccommodationsStore()
const emit = defineEmits([
	EmitsEnum.ErrorStatusChange,
	EmitsEnum.Change,
	EmitsEnum.Cancel,
	EmitsEnum.AccommodationIdChange,
])

// already existing modifiers
const updateAvailableModifiers = async () => {
	await nextTick()
	if (dateRange.value) {
		const roomTypeIds = accommodationStore
			.getRoomTypesByAccommodationId(selectedAccommodationId?.value!)
			.map((el) => el.id)
		modifiersStore.requestForRange(dateRange.value, roomTypeIds)
	}
}
watch(dateRange, updateAvailableModifiers)
onMounted(updateAvailableModifiers)

// Accommodation's id management
const onAccommodationSelect = (id: number) => {
	emit(EmitsEnum.AccommodationIdChange, id)
	selectedRoomTypes.value = []
	updateAvailableModifiers()
}
const currency = computed(() => accommodationStore.getAccommodationById(selectedAccommodationId?.value!)!.currency)

// roomTypeId management
const selectedRoomTypes: Ref<number[]> = ref([])
const onRoomTypeChange = (idList: number[]) => {
	selectedRoomTypes.value = idList
	removeUnselectedModifiers()

	// if keys are unselected, cancel their error state
	const removedKeys = Array.from(modifiersErrors.value.keys()).filter((el) => !idList.includes(el))
	removedKeys.forEach((key) => modifiersErrors.value.delete(key))

	emit(EmitsEnum.Change, modifiers.value)
	emit(EmitsEnum.ErrorStatusChange, Array.from(modifiersErrors.value.values()).includes(true))
}

// modifiers's management
const modifiers = ref(new Map<number, Modifiers>())
const modifiersErrors = ref(new Map<number, Boolean>())
const getModifierProps = (roomTypeId: number) => {
	return {
		modifiers:
			modifiers.value.get(roomTypeId) || pricesStore.getModifiersByRoomInDateRange(roomTypeId, dateRange.value),
		onChange: onModifiersChange(roomTypeId),
		onCancel: onModifierRemove(roomTypeId),
		onErrorStatusChange: (hasError: boolean) => {
			onModeErrorStatusChange(roomTypeId, hasError)
			emit(EmitsEnum.ErrorStatusChange, Array.from(modifiersErrors.value.values()).includes(true))
		},
		removableModifiers: modifiersStore.getModifiersForRoomTypeId(roomTypeId),
		currency,
		associatedModifiers: [],
		modifiersFilters: accommodationStore.getRoomTypeById(roomTypeId)!.editableModifiers,
	}
}
const onModifiersChange = (assignedId: number) => (newModifiers: Modifiers) => {
	if (selectedRoomTypes.value.includes(assignedId)) {
		modifiers.value.set(assignedId, newModifiers)
		emit(EmitsEnum.Change, modifiers.value)
	}
}
const onModifierRemove = (id: number) => (modifier: ModifierType) => emit(EmitsEnum.Cancel, [id], modifier)
const onModeErrorStatusChange = (id: number, hasError: boolean) => modifiersErrors.value.set(id, hasError)
const removeUnselectedModifiers = () => {
	const keysArray = Array.from(modifiers.value.keys())
	keysArray.forEach((el) => {
		if (!selectedRoomTypes.value.includes(el as number)) {
			modifiers.value.delete(el as number)
		}
	})
}

// Checkbox's childs's Management
const getModifierChild = (roomTypeId: number) => (allowEdit.value ? ModifiersManager : undefined)
const getCheckboxProps = (id: number) => {
	const roomType = accommodationStore.getRoomTypeById(id)

	// hint if derived
	let hint = undefined
	if (roomType?.linkedRoomType != undefined) {
		hint = useLocale().translate(TranslationKeys.ROOM_DERIVED_FROM, [roomType!.linkedRoomType!.name || ''])
	}

	return {
		disabled: roomType == undefined || roomType.editableModifiers.length <= 0,
		hint,
	}
}
const disabled = computed(() => !dateRange.value)
</script>

<template>
	<AccommodationDropdownList
		:disabled="disabled"
		:selected-accommodation-id="selectedAccommodationId"
		@change="onRoomTypeChange"
		:get-child="getModifierChild"
		:get-child-props="getModifierProps"
		@select="onAccommodationSelect"
		:get-checkbox-props="getCheckboxProps"
		:test-ids="testIds"
	/>
</template>
