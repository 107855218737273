<script lang="ts" setup>
import { PropType } from 'vue'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { IdModal } from '~~/src/constants/IdModal'
import { useEventsStore } from '~~/src/store/events'
import { useModalStore } from '~~/src/store/modals'
import { EventType } from '~~/src/submodules/sharedTypes/common/Event'
import { eventsCloseTracking, eventsOpenTracking, EventStep } from '~~/src/tracking/eventsTracking'
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration'

const props = defineProps({
	config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
	modalId: { type: String as PropType<IdModal>, required: true },
})
const { config } = toRefs(props)
const eventStore = useEventsStore()

const isDisabled = computed(() => eventStore.editableEvent.isUserDisabled)
const isUserGenerated = computed(() => eventStore.editableEvent.isUserGenerated)
const isUserModified = computed(() => eventStore.editableEvent.isUserModified)
const isDerivedEvent = computed(() => eventStore.editableEvent.isDrivedEvent)

const title = computed(() => {
	const label = eventStore.editableEvent.label
	const currentLocale = useLocale().currentLocale

	// @ts-ignore
	return label[currentLocale.value] || label.en || label.it
})

const onDisableEvent = () => {
	eventStore.toggleCurrentEventVisibility()

	useModalStore().closeModal(IdModal.EventDetails)
}

const onDeleteEvent = () => {
	eventStore.deleteCurrentEvent()

	useModalStore().closeModal(IdModal.EventDetails)
}

const configToForward = computed(() => {
	let cancelButton = undefined

	if (eventStore.editableEvent.isUserGenerated) {
		cancelButton = {
			text: TranslationKeys.DELETE,
			effect: onDeleteEvent,
			buttonType: ButtonTypes.DANGER,
		}
	} else {
		cancelButton = eventStore.editableEvent.isUserDisabled
			? {
					text: TranslationKeys.CANCEL,
					effect: () => {
						useModalStore().closeModal(IdModal.DisabledEventDetails)
						onClose()
					},
					buttonType: ButtonTypes.SECONDARY,
				}
			: {
					text: TranslationKeys.DISABLE,
					effect: onDisableEvent,
					buttonType: ButtonTypes.DANGER,
				}
	}

	// chi cazzo ha detto che è un ottima idea mettere le configurazioni dei modali in una hashmap separata da tutto il resto? - A.F.
	return {
		...config.value,
		buttons: isDerivedEvent.value
			? [
					{
						text: useLocale().translate(TranslationKeys.CLOSE),
						effect: () => {
							useModalStore().closeTopModal()
							onClose()
						},
					},
				]
			: config.value.buttons,
		cancelButton: isDerivedEvent.value ? undefined : cancelButton,
		title: { key: title.value },
		label: {
			key:
				eventStore.editableEvent.eventType === EventType.Event ? TranslationKeys.EVENTO : TranslationKeys.FESTIVITIES,
		},
		onClose,
	}
})

eventsOpenTracking(eventStore.editableEvent)

const parentAccommodationName = computed(() => {
	const parentAccommodation = eventStore.editableEvent.derivedEventAccommodationId
		? useAccommodationsStore().getAccommodationById(eventStore.editableEvent.derivedEventAccommodationId)
		: undefined
	return parentAccommodation ? parentAccommodation.name : undefined
})

const editOriginalEvent = () => {
	// closes current modal with event of the parent strategy
	useModalStore().closeTopModal()
	onClose()

	// opens the modal with the parent strategy event
	eventStore.selectedAccommodations = [eventStore.editableEvent.derivedEventAccommodationId!]
	eventStore.eventInteractionAccommodationId = eventStore.editableEvent.derivedEventAccommodationId!
	eventStore.editableEvent.isDrivedEvent = false
	eventStore.editableEvent.derivedEventAccommodationId = undefined

	if (eventStore.editableEvent.isUserDisabled) {
		useModalStore().addModal(IdModal.DisabledEventDetails)
	} else {
		useModalStore().addModal(IdModal.EventDetails)
	}
}

const onClose = () => {
	if (useModalStore().modals.length == 0) {
		eventsCloseTracking(eventStore.editableEvent, EventStep.DETAIL_VIEW)
	}
}
</script>

<template>
	<ModalGeneric :modal-id="modalId" :config="configToForward" style="width: 720px">
		<div class="flex flex-col gap-7">
			<!-- warning messages -->
			<div class="flex flex-col gap-1" v-if="isDisabled || isUserGenerated || isUserModified">
				<CommonIconWarning
					v-if="isDisabled"
					:icon="SpSvg.CalendarUserAlt"
					:text="
						eventStore.editableEvent.eventType === EventType.Event
							? TranslationKeys.DISABLED_EVENT
							: TranslationKeys.DISABLED_FESTIVITY
					"
				/>
				<CommonIconWarning
					v-if="isUserGenerated"
					:icon="SpSvg.CalendarUserAlt"
					:text="
						eventStore.editableEvent.eventType === EventType.Event
							? TranslationKeys.USER_GENERATED_EVENT
							: TranslationKeys.USER_GENERATED_FESTIVITY
					"
				/>
				<CommonIconWarning
					v-if="isUserModified"
					:icon="SpSvg.CalendarUserAlt"
					:text="
						eventStore.editableEvent.eventType === EventType.Event
							? TranslationKeys.USER_EDITED_EVENT
							: TranslationKeys.USER_EDITED_FESTIVITY
					"
				/>
			</div>

			<!-- event weights -->
			<div class="flex pb-1">
				<EventsImpactsIndicator
					:impacts="eventStore.editableEventDetails.impacts"
					:event-type="eventStore.editableEvent.eventType"
				/>
			</div>

			<!-- event warning for derived strategy -->
			<div v-if="isDerivedEvent" class="w-full">
				<SpNotification
					class="w-full max-w-max"
					:dismissable="false"
					:type="SpNotificationType.Warning"
					:variant="SpNotificationVariant.Inline"
					:title="$t(TranslationKeys['calendar.events.derived-strategy-event-warning-title'])"
				>
					<template #body>
						<SpText :size="SpTextSize.BodyRegular01" class="text-dark-blue-900">
							{{ $t(TranslationKeys['calendar.events.derived-strategy-event-warning-text']) }}
							<SpButton :type="SpButtonType.Link" class="inline-flex" @click="editOriginalEvent"
								>{{ parentAccommodationName }}↗</SpButton
							>
						</SpText>
					</template>
				</SpNotification>
			</div>
		</div>
	</ModalGeneric>
</template>
