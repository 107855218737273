<script lang="ts" setup>
import { PropType } from 'vue';
import { TranslationKeys } from '~/i18n/TranslationKeys';
import { CardTypes } from '~~/src/constants/cardTypes';
import { IdModal } from '~~/src/constants/IdModal';
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration';
import { IMessageCardData } from '~~/src/types/IMessageCardData';

const props = defineProps({
    config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
    modalId: { type: String as PropType<IdModal>, required: true }
})
const { config, modalId } = toRefs(props)

const content: IMessageCardData = {
    canClose: false,
    cardType: CardTypes.WARNING,
    title: TranslationKeys.MODIFIERS_DETECTED,
    message: TranslationKeys.MODIFIERS_DETECTED_TEXT
}

</script>

<template>
    <ModalGeneric :modal-id="modalId" :config="config" style="width: 600px">
        <CommonMessageCard :card-data="content" />
    </ModalGeneric>
</template>