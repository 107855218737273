<script setup lang="ts">
import { TranslationKeys } from '~/i18n/TranslationKeys';
import { SpSvg } from '~~/src/autogen/SpSvg';
import { IdModal } from '~~/src/constants/IdModal';
import { useAccommodationsStore } from '~~/src/store/accommodations';
import { useEventsStore } from '~~/src/store/events';
import { useModalStore } from '~~/src/store/modals';
import { useModifiersStore } from '~~/src/store/modifiers';

const eventStore = useEventsStore()
const accommodationStore = useAccommodationsStore()
const modalStore = useModalStore()
const modifiersStore = useModifiersStore()

const accommodations = computed(() => eventStore.selectedAccommodations)
const accommodationNames = computed(() => accommodations.value.map(el => accommodationStore.getAccommodationById(el)!.name))
const selectedAccommodationIndex = ref(0)
const selectedAccommodation = computed(() => accommodations.value[selectedAccommodationIndex.value])
const currentCurrency = computed(() => accommodationStore.getAccommodationById(selectedAccommodation.value)!.currency)
const selectedRoomType = ref(accommodationStore.getRoomTypesByAccommodationId(selectedAccommodation.value).filter(roomType => {
    return !roomType.isIndexed
})[0].id)
const hasModifiers = computed(() => modifiersStore.modifiers.length > 0)
const isBlurred = computed(() => !!accommodationStore.getRoomTypeById(selectedRoomType.value)?.isIndexed)

const onAccommodationChange = (position: number) => {
    selectedAccommodationIndex.value = position
    selectedRoomType.value = accommodationStore.getRoomTypesByAccommodationId(selectedAccommodation.value)[0].id
}
const onRoomTypeChange = (id: number) => selectedRoomType.value = id

const onModifiersRemove = () => {
    modalStore.addModal(IdModal.EventRemoveModifiers)
}
</script>

<template>
    <div class="flex flex-col h-full">

        <AnimationCollapse>
            <div v-if="hasModifiers" class="flex items-center justify-between p-2 mb-2 bg-yellow-100 rounded">
                <div class="flex items-center gap-2">
                    <CommonIcon :icon-name="SpSvg.BasicAlertCircle" fill="fill-yellow-900" />
                    <CommonText :text="TranslationKeys.WARNING_MODIFIERS_AVAILABLE" class="text-yellow-900" />
                </div>
                <div>
                    <CommonText :text="TranslationKeys.REMOVE" @click=onModifiersRemove
                        class="text-yellow-900 cursor-pointer hover:underline" />
                </div>
            </div>
        </AnimationCollapse>

        <div class="grid grid-cols-[1fr_1fr] grid-rows-[min-content_1fr] gap-3 w-full h-full">
            <div class="grid w-full">
                <CommonLabel v-if="accommodationNames.length > 1" :text="TranslationKeys.ACCOMMODATIONS">
                    <CommonDropdown :entries="accommodationNames" :selected-entry="selectedAccommodationIndex"
                        @click="onAccommodationChange" />
                </CommonLabel>
            </div>
            <div class="grid w-full">
                <CommonLabel :text="TranslationKeys.ROOM_TYPE">
                    <RoomTypeDropdown :accommodation-id="selectedAccommodation" :selected-id="selectedRoomType"
                        @change="onRoomTypeChange" :hideIndexedRooms="false" :disable-indexed-rooms="true" :hideDerivedRooms="true" />
                </CommonLabel>
            </div>
            <div class="h-full col-span-2">
                <SpBlur :is-blurred="isBlurred">
                    <ChartsEventPreview :event-preview="eventStore.eventPreview" :current-preview="eventStore.currentPreview"
                        :event-with-modifiers-preview="eventStore.eventWithModifiersPreview" :currency="currentCurrency"
                        :selected-room-type="selectedRoomType"
                        :data-selection-required="eventStore.editableEventDetails.impacts.length < 1" />

                        <template #placeholder class="flex flex-col justify-start">
                            <SpNotification
                                class="max-w-full mb-6"
                                :type="SpNotificationType.Info"
                            :title="useLocale().translate(TranslationKeys.IMPACT_PREVIEW_NOT_AVAILABLE_TITLE)"
                                :text="useLocale().translate(TranslationKeys.IMPACT_PREVIEW_NOT_AVAILABLE_BODY)"
                                :dismissable="false"
                            />
                        </template>
                </SpBlur>
            </div>
        </div>
    </div>
</template>