import { addDays, eachDayOfInterval, isSameDay } from 'date-fns'
import { cloneDeep } from 'lodash'
import { defineStore } from 'pinia'
import { TrackingMessages } from '@/constants/trackingMessages'
import { Range } from '~/submodules/sharedTypes/common/Range'
import { basePricesConfiguration } from '../config/BasePrices'
import { CardTypes } from '../constants/cardTypes'
import { DataPoint } from '../submodules/sharedTypes/common/DataPoint'
import { GetAccommodationBasePricesResponse } from '../submodules/sharedTypes/communication/base-prices/get/GetAccommodationBasePricesResponse'
import { GetAccommodationBasePricesPredictionPreviewNetworkObject } from '../submodules/sharedTypes/communication/base-prices/preview/GetAccommodationBasePricesPredictionPreviewNetworkObject'
import { GetAccommodationBasePricesPredictionPreviewResponse } from '../submodules/sharedTypes/communication/base-prices/preview/GetAccommodationBasePricesPredictionPreviewResponse'
import { INamedArray } from '../types/INamedArray'
import { utilNetwork } from '../utils/UtilNetwork'
import { utilDate } from '../utils/utilDate'
import { BasePrice, BasePricePredictionPreviewType } from './../submodules/sharedTypes/common/BasePrice'
import { GetAccommodationBasePricesNetworkObject } from './../submodules/sharedTypes/communication/base-prices/get/GetAccommodationBasePricesNetworkObject'
import { UpdateAccommodationBasePricesNetworkObject } from './../submodules/sharedTypes/communication/base-prices/update/UpdateAccommodationBasePricesNetworkObject'
import { utilBasePrices } from './../utils/utilBasePrice'
import { useNotificationsStore } from './notifications'
import { TweakModifierType } from '~/submodules/sharedTypes/common/Modifiers'
import { useLoadingStore } from '@/store/loading'
import { LoadingIds } from '@/constants/loadingIds'
import { useAccommodationsStore } from './accommodations'
import { useFeedbackStore } from './feedback'
import { FeedbackId } from '../constants/FeedbackId'
import { TranslationKeys } from '~/i18n/TranslationKeys'

type BasePricesState = {
	basePrices: Map<number, Map<number, BasePrice[]>>
	selectedBasePricesIndexes: Map<number, Map<number, number[]>>
	//                         Map<accommodationId, Map<RoomTypeId, index>>
	selectedAccommodationId: number
	selectedRoomTypeId: number
	selectedBasePrices: BasePrice[]
	selectedRoomTypeIds: number[]
	minSelectableDays: number
	priceModifiers: Map<number, TweakModifierType>
	editableBasePrice: {
		accommodationId: number
		roomTypeId: number
		basePrice: BasePrice
		originalIndex?: number
	}
	basePricesToDelete: {
		accommodationId: number
		roomTypeId: number
		priceIndexes: number[]
	}

	confirmedDelete: boolean
	confirmedAdd: boolean
	changes: {
		hasChanges: boolean
		index: number
	}

	previews: {
		[BasePricePredictionPreviewType.Regular]: Map<number, DataPoint<Date, number>[]>
		[BasePricePredictionPreviewType.Temporary]: Map<number, DataPoint<Date, number>[]>
	}
}

export const useBasePricesStore = defineStore('💵 Base prices', {
	state: (): BasePricesState => ({
		basePrices: new Map(),
		selectedBasePricesIndexes: new Map(),
		selectedAccommodationId: -1,
		selectedRoomTypeId: -1,
		minSelectableDays: basePricesConfiguration.minimumDaysInRange,
		priceModifiers: new Map(),
		selectedRoomTypeIds: [],
		selectedBasePrices: [],

		editableBasePrice: {
			accommodationId: 0,
			roomTypeId: 0,
			basePrice: {} as BasePrice,
			originalIndex: undefined,
		},

		basePricesToDelete: {
			accommodationId: 0,
			roomTypeId: 0,
			priceIndexes: [],
		},

		confirmedDelete: false,
		confirmedAdd: false,
		changes: {
			hasChanges: false,
			index: -1,
		},

		previews: {
			[BasePricePredictionPreviewType.Regular]: new Map(),
			[BasePricePredictionPreviewType.Temporary]: new Map(),
		},
	}),
	actions: {
		createNewEditableBasePrice(
			accommodationId: number,
			roomTypeId: number,
			dateRange: Range<Date>,
			basePrice: number = basePricesConfiguration.defaultBasePrice
		) {
			this.editableBasePrice.accommodationId = accommodationId
			this.editableBasePrice.roomTypeId = roomTypeId
			this.editableBasePrice.originalIndex = undefined
			this.editableBasePrice.basePrice = {
				price: basePrice,
				dateRange,
			}
		},
		editBasePrice(accommodationId: number, roomTypeId: number, priceIndex: number) {
			this.editableBasePrice.accommodationId = accommodationId
			this.editableBasePrice.roomTypeId = roomTypeId
			this.editableBasePrice.basePrice = cloneDeep(this.basePrices.get(accommodationId)!.get(roomTypeId)![priceIndex])
			this.editableBasePrice.originalIndex = priceIndex
		},
		resetEditableBasePrice() {
			this.editableBasePrice = {
				accommodationId: 0,
				roomTypeId: 0,
				basePrice: {} as BasePrice,
				originalIndex: undefined,
			}
		},
		requestBasePrices(accommodationId: number) {
			if (accommodationId == -1) {
				return
			}

			useLoadingStore().addLoading(LoadingIds.ACCOMMODATIONS_BASE_PRICES)
			utilNetwork.simpleRequest(new GetAccommodationBasePricesNetworkObject({ accommodationId }))
		},
		setSelectedAccommodationId(accommodationId: number) {
			this.selectedAccommodationId = accommodationId
		},
		setSelectedRoomTypeId(roomTypeId: number) {
			this.selectedRoomTypeId = roomTypeId
		},
		setSelectedRoomTypeIds(roomTypeIds: number[]) {
			this.selectedRoomTypeIds = roomTypeIds
		},
		setPriceModifiers(priceModifiers: Map<number, TweakModifierType>) {
			this.priceModifiers = priceModifiers
		},
		setSelectedBasePrices(basePrices: BasePrice[]) {
			this.selectedBasePrices = basePrices
		},
		setSelectedBasePricesIndexes(selectedBasePricesIndexes: Map<number, Map<number, number[]>>) {
			this.selectedBasePricesIndexes = selectedBasePricesIndexes
		},
		setBasePrices(res: GetAccommodationBasePricesResponse) {
			const { accommodationId, roomTypes, minDaysInRange } = res
			const accommodationValidRoomTypeIds = useAccommodationsStore()
				.basePricesRoomTypesEnabled(accommodationId)
				?.filter((roomType) => !roomType.isIndexed)
				.map((roomType) => roomType.id)
			const filteredRoomTypesBasePrices = roomTypes.filter(
				(roomType) => accommodationValidRoomTypeIds?.includes(roomType.id)
			)

			if (!this.basePrices.has(accommodationId)) {
				this.basePrices.set(accommodationId, new Map())
			}
			const accommodationMap = this.basePrices.get(accommodationId)!

			filteredRoomTypesBasePrices.forEach((el) => accommodationMap.set(el.id, el.basePrices))

			this.minSelectableDays = minDaysInRange || basePricesConfiguration.minimumDaysInRange

			useLoadingStore().removeLoading(LoadingIds.ACCOMMODATIONS_BASE_PRICES)
		},
		requestEditablePreview() {
			const { accommodationId, roomTypeId, basePrice, originalIndex } = this.editableBasePrice
			const accommodation = this.basePrices.get(accommodationId)
			if (!accommodation || !basePrice || !originalIndex) {
				return
			}

			// here we need all the base prices to match the API request interface
			const basePrices = Array.from(accommodation, ([id, value]) => ({
				id,
				basePrices: value,
			}))

			const roomIndex = basePrices.findIndex((basePrice) => basePrice.id === roomTypeId)
			const basePricesClone = cloneDeep(basePrices)
			basePricesClone[roomIndex].basePrices[originalIndex].price = basePrice.price

			// finally, asks for preview
			utilNetwork.simpleRequest(
				new GetAccommodationBasePricesPredictionPreviewNetworkObject({
					accommodationId: this.editableBasePrice.accommodationId,
					previewType: BasePricePredictionPreviewType.Temporary,
					roomTypes: basePricesClone.filter((bpc) => bpc.id === roomTypeId),
				})
			)
			useLoadingStore().addLoading(LoadingIds.GET_PREDICTION_PREVIEW)
		},
		setPricesPreview(res: GetAccommodationBasePricesPredictionPreviewResponse) {
			res.roomTypes.forEach((data) => {
				this.previews[res.previewType].set(data.id, data.dataPoints)
			})
			useLoadingStore().removeLoading(LoadingIds.GET_PREDICTION_PREVIEW)
		},
		addBasePrice(editableBP = this.editableBasePrice) {
			const { basePrice, accommodationId, roomTypeId, originalIndex } = cloneDeep(editableBP)

			if (!this.basePrices.has(accommodationId)) {
				this.basePrices.set(accommodationId, new Map())
			}

			const accommodationMap = this.basePrices.get(accommodationId)!
			if (!accommodationMap.has(roomTypeId)) {
				accommodationMap.set(roomTypeId, [])
			}

			let oldPrices = accommodationMap.get(roomTypeId)!
			if (originalIndex != undefined) {
				oldPrices = utilArray.removeAt(oldPrices, originalIndex)
			}

			const { insertionIndex, basePrices } = utilBasePrices.insertAndReshapeBasePrices(oldPrices, basePrice)
			accommodationMap.set(roomTypeId, basePrices)

			this.changes.hasChanges = true
			this.changes.index = insertionIndex

			useNotificationsStore().addNotification({
				canClose: true,
				cardType: CardTypes.SUCCESS,
				title: TranslationKeys.BASE_PRICES_ADDED,
				message: TranslationKeys.BASE_PRICES_ADDED_TEXT,
				messageReplacements: [
					utilNumber.toCurrency(
						basePrice.price,
						useAccommodationsStore().getAccommodationById(accommodationId)?.currency || { code: 'eur' }
					),
					utilDate.formatDate(basePrice.dateRange.from),
					utilDate.formatDate(basePrice.dateRange.to),
					useAccommodationsStore().getRoomTypeById(roomTypeId)?.name || '',
				],
			})

			utilTracking.track(TrackingMessages.PRICE_PERIOD_SAVE, {
				days_num: utilDate.daysDifference(basePrice.dateRange),
			})
		},
		savePricesForAccommodation(accommodationId: number) {
			const roomTypes: any[] = []
			this.basePrices.get(accommodationId)!.forEach((val, key) =>
				roomTypes.push({
					id: key,
					basePrices: val,
				})
			)

			utilNetwork.simpleRequest(
				new UpdateAccommodationBasePricesNetworkObject({
					accommodationId,
					roomTypes,
				})
			)

			useNotificationsStore().addNotification({
				canClose: true,
				cardType: CardTypes.SUCCESS,
				title: TranslationKeys.BASE_PRICES_SAVED,
				message: TranslationKeys.BASE_PRICES_SAVED_TEXT,
			})
			utilTracking.track(TrackingMessages.PRICE_LIST_SAVE, {
				is_successfull: this.accommodationHasError(accommodationId),
			})
			useFeedbackStore().requestFeedback(FeedbackId.PriceListSave)
			this.changes.hasChanges = false
			this.changes.index = -1
		},

		setDeletingBasePrices(accommodationId: number, roomTypeId: number, priceIndexes: number[]) {
			this.basePricesToDelete.accommodationId = accommodationId
			this.basePricesToDelete.roomTypeId = roomTypeId
			this.basePricesToDelete.priceIndexes = priceIndexes
		},
		setConfirmedDelete(confirmed: boolean) {
			this.confirmedDelete = confirmed
		},
		setConfirmedAdd(confirmed: boolean) {
			this.confirmedAdd = confirmed
		},
		deleteBasePrices(accommodationId: number, roomTypeId: number, priceIndexes: number[]) {
			if (this.basePrices.has(accommodationId)) {
				const oldPrices = this.basePrices.get(accommodationId)!.get(roomTypeId)!

				this.basePrices.get(accommodationId)!.set(roomTypeId, utilArray.removeAtMany(oldPrices, priceIndexes))
			}

			this.changes.hasChanges = true
			this.changes
			// this.basePrices.get(accommodationId)!.set(roomTypeId, utilArray.removeAtMany(oldPrices, priceIndexes))
		},
	},
	getters: {
		accommodationHasError(): (id: number) => boolean {
			return (id: number) => {
				if (useLoadingStore().isLoading(LoadingIds.ACCOMMODATIONS_BASE_PRICES)) {
					return false
				}

				const accommodationPrices = this.basePrices.get(id)
				if (!accommodationPrices) {
					return true
				}

				return Array.from(accommodationPrices.keys()).some((roomId) => this.roomTypeHasError(id, roomId as number))
			}
		},
		roomTypeHasError(): (accommodationId: number, roomTypeId: number) => boolean {
			return (accommodationId: number, roomTypeId: number) => {
				if (useLoadingStore().isLoading(LoadingIds.ACCOMMODATIONS_BASE_PRICES)) {
					return false
				}

				const prices = this.basePrices.get(accommodationId)?.get(roomTypeId)

				return (
					prices == undefined ||
					!utilBasePrices.coversRequiredTimeSpan(prices) ||
					!utilBasePrices.hasMinDaysInRange(prices, this.minSelectableDays)
				)
			}
		},
		isEditableBasePriceValid(): boolean {
			const dateRange = this.editableBasePrice.basePrice.dateRange
			return utilDate.daysDifference(dateRange) + 1 >= this.minSelectableDays
		},
		getBasePricesByAccommodationAndRoomTypeId() {
			return (accommodationId: number, roomTypeId: number) =>
				this.basePrices.get(accommodationId)?.get(roomTypeId) || []
		},
		getAccommodationBasePrices() {
			return (accommodationId: number) => this.basePrices.get(accommodationId) || new Map()
		},
		getSelectedAccommodationId(): number {
			return this.selectedAccommodationId || 0
		},
		getSelectedRoomTypeId(): number {
			return this.selectedRoomTypeId || 0
		},
		getSelectedRoomTypeIds(): number[] {
			return this.selectedRoomTypeIds || []
		},
		getPriceModifiers(): Map<number, TweakModifierType> {
			return this.priceModifiers || new Map()
		},
		getSelectedBasePrices(): BasePrice[] {
			return this.selectedBasePrices || []
		},
		getChartBasePrices(): any {
			return (accommodationId: number, roomTypeId: number): INamedArray<DataPoint<Date, number>> => {
				let consideredPrices: BasePrice[] = []

				if (roomTypeId === this.editableBasePrice.roomTypeId) {
					const { basePrices } = utilBasePrices.insertAndReshapeBasePrices(
						this.basePrices.get(accommodationId)?.get(roomTypeId) || [],
						this.editableBasePrice.basePrice
					)
					consideredPrices = basePrices
				} else {
					const { basePrices } = utilBasePrices.insertAndReshapeBasePrices(
						this.basePrices.get(accommodationId)?.get(roomTypeId) || []
					)
					consideredPrices = basePrices
				}

				// base prices conversion
				const convertedBasePrices: DataPoint<Date, number>[] = []
				consideredPrices.forEach((el, index) => {
					const datesInRange = eachDayOfInterval({
						start: el.dateRange.from,
						end: el.dateRange.to,
					})

					datesInRange.forEach((basePriceDate) => {
						convertedBasePrices.push({
							x: basePriceDate,
							y: el.price,
						})
					})
					if (index < consideredPrices.length - 1 && utilBasePrices.hasEmptySlots([el, consideredPrices[index + 1]])) {
						convertedBasePrices.push({
							x: el.dateRange.to,
							y: NaN,
						})
					}
				})
				const lastBasePrice = consideredPrices[consideredPrices.length - 1]
				if (lastBasePrice != undefined) {
					convertedBasePrices.push({
						x: lastBasePrice.dateRange.to,
						y: lastBasePrice.price,
					})
				}

				return {
					name: TranslationKeys.BASE_PRICE,
					entries: convertedBasePrices,
				}
			}
		},
		getChartPreview() {
			return (
				roomTypeId: number,
				chartType: BasePricePredictionPreviewType = BasePricePredictionPreviewType.Regular
			): INamedArray<DataPoint<Date, number>> => {
				const previewData = this.previews[chartType].get(roomTypeId) || []
				return {
					name: TranslationKeys.SUGGESTED_PRICE,
					entries: previewData,
				}
			}
		},
		getHighlightedDates(): Range<Date>[] {
			const { accommodationId, roomTypeId } = this.editableBasePrice
			const { from, to } = this.editableBasePrice.basePrice.dateRange

			return this.getBasePricesByAccommodationAndRoomTypeId(accommodationId, roomTypeId)
				.filter((el) => !isSameDay(el.dateRange.from, from) && !isSameDay(el.dateRange.to, to))
				.map((el) => el.dateRange)
		},
		getEditedDates(): Range<Date>[] {
			const { accommodationId, roomTypeId } = this.editableBasePrice
			const index = this.editableBasePrice.originalIndex

			if (index == undefined) {
				return []
			}
			return [this.getBasePricesByAccommodationAndRoomTypeId(accommodationId, roomTypeId)![index].dateRange]
		},
		getBasePriceToDelete(): any {
			return this.basePricesToDelete
		},
	},
})
