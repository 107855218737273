<script setup lang="ts">
import { addDays, isAfter, isSameDay } from 'date-fns';
import { TranslationKeys } from '~/i18n/TranslationKeys';
import { TextSizes } from '~~/src/constants/textSizes';
import { useAccommodationsStore } from '~~/src/store/accommodations';
import { useEventsStore } from '~~/src/store/events';
import { useModifiersStore } from '~~/src/store/modifiers';
import { EventType } from '~~/src/submodules/sharedTypes/common/Event';
import { utilCalendar } from '~~/src/utils/utilCalendar';

const eventsStore = useEventsStore()
const currentLocale = useLocale().currentLocale
const modifiersStore = useModifiersStore()
const accommodationStore = useAccommodationsStore()

const today = new Date()
const maxDate = computed(() => addDays(today, eventsStore.getTimePadding - 1))
const eventType = computed(() => eventsStore.editableEvent.eventType)
const eventColor = computed(() => eventType.value === EventType.Event ? 'events' : 'festivities')

// TODO: use utilDate
const currentSelectedDate: any = ref(eventsStore.editableEventDetails.impacts.find(el => isAfter(el.date, today) || isSameDay(el.date, today))?.date)
const highlightedDates = ref([] as any)
const updateHighlights = (): any[] => highlightedDates.value = [...utilCalendar.datesToHighlightedDates((eventsStore.editableEventDetails.impacts || []).map(el => el.date), eventColor.value, 'light')]

updateHighlights()

const onEventDelete = () => {
    eventsStore.editableEventDetails.impacts = eventsStore.editableEventDetails.impacts.filter(el => !isSameDay(currentSelectedDate.value!, el.date))
    currentSelectedDate.value = null
    onEventUpdate()
}

const onEventUpdate = () => {
    updateHighlights()

    if (eventsStore.editableEventDetails.impacts.length < 1) {
        eventsStore.resetPreviews()
        return
    }
    eventsStore.requestPreview()
    updateModifiers()
}

const updateModifiers = () => {
    const impacts = eventsStore.editableEventDetails.impacts || []

    if (impacts.length == 0) {
        modifiersStore.clearModifiers()
        return
    }

    const dateRange = {
        from: impacts[0].date,
        to: impacts[impacts.length - 1].date
    }
    const roomTypeIds = eventsStore.selectedAccommodations
        .map(el => accommodationStore.getRoomTypesByAccommodationId(el))
        .reduce((prev, next) => [...prev, ...next], [])
        .map(el => el.id)

    modifiersStore.requestForRange(dateRange, roomTypeIds, false)
}
</script>

<template>
    <div class="flex gap-6">
        <CommonFrameWithTitle :title="TranslationKeys.IMPACT" class="flex-1">
            <slot>
                <div class="flex flex-col gap-6">
                    <CommonText :text="TranslationKeys.IMPACT_EXPLAINATION" :text-size="TextSizes.BODY_REGULAR" />
                    <div class="flex">
                        <v-date-picker :locale="currentLocale" style="border:none" :attributes="highlightedDates"
                            v-model="currentSelectedDate" :color="eventColor" :min-date="today" :max-date="maxDate" />
                        <div class="h-full border-l border-dark-blue-300" />

                        <div class="pl-3 grow">
                            <CommonText v-if="currentSelectedDate == undefined"
                                :text="TranslationKeys.SELECT_A_DATE_TO_EDIT_IMPACTS" class="text-dark-blue-500" />
                            <EventsEditor v-else :selected-date="currentSelectedDate" @delete="onEventDelete"
                                @change="onEventUpdate" />
                        </div>
                    </div>
                </div>
            </slot>
        </CommonFrameWithTitle>

        <CommonFrameWithTitle :title="TranslationKeys.PRICE_PREVIEW" class="flex-1">
            <EventsImpactsPreview />
        </CommonFrameWithTitle>
    </div>
</template>
