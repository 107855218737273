<script setup lang="ts">
import { PropType } from 'nuxt/dist/app/compat/vue-demi';
import { IdModal } from '~~/src/constants/IdModal';
import { useModalStore } from '~~/src/store/modals';
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration';


const props = defineProps({
    config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
    modalId: { type: String as PropType<IdModal>, required: true }
})
const { config, modalId } = toRefs(props)
const modalStore = useModalStore()

const innerProps = computed(() => ({ ...config.value, allowClose: modalStore.mobile.allowClose, title: modalStore.mobile.title }))

</script>

<template>
    <ModalGeneric :modal-id="modalId" :config="innerProps" style="width: 600px" :is-mobile-modal="true">
        <div class="pb-2">
            <component :is="modalStore.mobile.component" v-bind="modalStore.mobile.props" />
        </div>
    </ModalGeneric>
</template>