<script setup lang="ts">
import { PropType } from 'vue'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'
import { EmitsEnum } from '~~/src/constants/emits'
import { useAccommodationsStore } from '~~/src/store/accommodations'

const props = defineProps({
	selectedAccommodations: { type: Object as PropType<Number[]>, default: [] },
	disabled: { type: Boolean, default: false },
	highlightedAccommodationId: Number,
	disableDerivedAccommodations: { type: Boolean, default: false },
})
const { selectedAccommodations, disabled, highlightedAccommodationId, disableDerivedAccommodations } = toRefs(props)
const emit = defineEmits([EmitsEnum.Change])

const accommodationStore = useAccommodationsStore()
const accommodations = computed(() => accommodationStore.accommodations)
const disabledAccommodationsIdsMap = computed(() => {
	const hashMap = new Map<number, boolean>()
	accommodationStore.accommodations
		.filter((accommodation) => {
			return !(!disableDerivedAccommodations.value || !accommodation.parentStrategyAccommodationId)
		})
		.forEach((accommodation) => {
			hashMap.set(accommodation.id, true)
		})

	return hashMap
})
const selectableAccommodations = computed(() =>
	accommodations.value.filter((accommodation) => !disabledAccommodationsIdsMap.value.has(accommodation.id))
)
const highlightedAccommodation = computed(() =>
	accommodationStore.getAccommodationById(highlightedAccommodationId?.value)
)

const onSelectAll = (select: Boolean) => {
	if (!select) {
		emit(EmitsEnum.Change, [])
	} else {
		emit(
			EmitsEnum.Change,
			selectableAccommodations.value.map((el) => el.id)
		)
	}
}

const onSelectSingle = (select: Boolean, id: number) => {
	if (disabledAccommodationsIdsMap.value.has(id)) return

	if (select) {
		emit(EmitsEnum.Change, [...selectedAccommodations.value, id])
	} else {
		emit(
			EmitsEnum.Change,
			selectedAccommodations.value.filter((el) => el != id)
		)
	}
}
</script>

<template>
	<CommonFrameWithTitle :title="TranslationKeys.ACCOMMODATIONS">
		<template v-slot:header>
			<CommonCheckbox
				:text="TranslationKeys.ALL_ACCOMMODATIONS"
				:clicked="selectedAccommodations.length == accommodations.length"
				:indeterminate="selectedAccommodations.length > 0 && selectedAccommodations.length < accommodations.length"
				:disabled="disabled"
				@toggle="onSelectAll"
			/>
		</template>

		<template v-slot:default>
			<CommonCheckbox
				v-if="highlightedAccommodation != undefined"
				:text="highlightedAccommodation.name as TranslationKey"
				:clicked="selectedAccommodations.some((el) => el == highlightedAccommodation!.id)"
				@toggle="(click: boolean) => onSelectSingle(click, highlightedAccommodation!.id)"
				:disabled="disabled"
			/>

			<template v-for="accommodation in accommodations">
				<CommonCheckbox
					v-if="accommodation.id != highlightedAccommodationId"
					:disabled="disabledAccommodationsIdsMap.has(accommodation.id) || disabled"
					:text="accommodation.name as TranslationKey"
					:clicked="selectedAccommodations.some((el) => el == accommodation.id)"
					:hint="
						disabledAccommodationsIdsMap.has(accommodation.id)
							? TranslationKeys['calendar.events.derived-strategy-event-accommodation-list-warning-text']
							: undefined
					"
					@toggle="(click: boolean) => onSelectSingle(click, accommodation.id)"
				/>
			</template>
		</template>
	</CommonFrameWithTitle>
</template>
