import { isPast, isSameDay } from 'date-fns'
import { cloneDeep } from 'lodash'
import { RangeInsertionData } from '~/types/RangeInsertionData'
import { basePricesConfiguration } from '../config/BasePrices'
import { BasePrice } from './../submodules/sharedTypes/common/BasePrice'
import { utilArray } from './utilArray'
import { utilDate } from './utilDate'

class UtilBasePrices {
	/**
	 * This function confirms wether the user needs to add base prices
	 * to the passed array
	 * @param prices , a SORTED BY START DATE and NOT OVERLAPPED array of BasePrices
	 */
	public coversRequiredTimeSpan(prices: BasePrice[]): boolean {
		return (
			prices.length > 0 &&
			this.hasRequiredAmountOfDays(prices) &&
			this.hasBasePeriodForToday(prices) &&
			!this.hasEmptySlots(prices)
		)
	}

	public hasMinDaysInRange(prices: BasePrice[], minDaysInRange: number) {
		return prices
			.filter((price) => !isPast(price.dateRange.to))
			.slice(1)
			.every((price) => utilDate.daysDifference(price.dateRange) >= minDaysInRange - 1)
	}

	public hasRequiredAmountOfDays(prices: BasePrice[]): boolean {
		const lastDate = prices[prices.length - 1].dateRange.to
		const daysAmount = utilDate.daysDifference({
			from: new Date(),
			to: lastDate,
		})

		return daysAmount >= basePricesConfiguration.minimumDaysToFillWithPrices - 1
	}

	public hasBasePeriodForToday(prices: BasePrice[]): boolean {
		const matchingBasePricePeriod = prices.find((el) => {
			const startsBeforeToday = utilDate.isBeforeToday(el.dateRange.from) || utilDate.isToday(el.dateRange.from)
			const endsAfterToday = utilDate.isAfterToday(el.dateRange.to) || utilDate.isToday(el.dateRange.to)

			return startsBeforeToday && endsAfterToday
		})

		return matchingBasePricePeriod != undefined
	}

	public hasEmptySlots(prices: BasePrice[]): boolean {
		const today = new Date()
		const indexStart = prices.findIndex((bp) => utilDate.dateInRange(today, bp.dateRange))
		const cleanPrices = indexStart > 0 ? prices.slice(indexStart - 1) : prices

		for (let i = 1; i < cleanPrices.length; i++) {
			const from = cleanPrices[i - 1].dateRange.to
			const to = cleanPrices[i].dateRange.from
			const daysDifference = utilDate.daysDifference({
				from,
				to,
			})

			if (daysDifference > 1) {
				return true
			}
		}

		return false
	}

	public insertAndReshapeBasePrices(
		oldPrices: BasePrice[],
		newPrice?: BasePrice
	): { insertionIndex: number; basePrices: BasePrice[] } {
		if (newPrice?.dateRange?.from == undefined) {
			return { insertionIndex: -1, basePrices: cloneDeep(oldPrices) }
		}
		// if there are no values, just return the new value in an array
		if (oldPrices.length == 0) {
			return { insertionIndex: 0, basePrices: [newPrice] }
		}

		const newPrices = cloneDeep(oldPrices)
		const insertionData = this.getInsertionData(newPrices, newPrice)

		if (insertionData.isFirstElement) {
			newPrices.unshift(newPrice)
			return { insertionIndex: 0, basePrices: newPrices }
		}

		if (insertionData.isLastElement) {
			newPrices.push(newPrice)
			return { insertionIndex: newPrices.length - 1, basePrices: newPrices }
		}

		const { from, to } = insertionData.positionInfo!

		// if the element completely overlaps another element
		const fullReplace =
			from == to &&
			isSameDay(newPrices[from].dateRange.from, newPrice.dateRange.from) &&
			isSameDay(newPrices[to].dateRange.to, newPrice.dateRange.to)
		if (fullReplace) {
			newPrices[from].price = newPrice.price
			return { insertionIndex: from, basePrices: newPrices }
		}

		const before = utilArray.stopAt(newPrices, from)
		const after = utilArray.startingFrom(newPrices, to + 1)
		const hasStartElement = from > -1
		let chunks: BasePrice[] = []

		if (hasStartElement) {
			chunks = this.chunkDownPrice(newPrices[from], newPrice, true)
		}
		if (from != to && newPrices[to]) {
			chunks = [...chunks, ...this.chunkDownPrice(newPrices[to], newPrice, !hasStartElement)]
		}
		// TODO: temporary commented, but it need explanations
		// else {
		// 	chunks = [...chunks, ...this.chunkDownPrice(newPrices[newPrices.length - 1], newPrice, !hasStartElement)]
		// }

		return { insertionIndex: before.length + 1, basePrices: [...before, ...chunks, ...after] }
	}

	private chunkDownPrice(oldPrice: BasePrice, newPrice: BasePrice, insertNewPrice: boolean = false): BasePrice[] {
		const result: BasePrice[] = []
		const overridesCompletely =
			oldPrice.dateRange.from == newPrice.dateRange.from && oldPrice.dateRange.to == newPrice.dateRange.to

		if (overridesCompletely) {
			return result
		}

		if (oldPrice.dateRange.to < newPrice.dateRange.from) {
			result.push(oldPrice)
		}

		if (insertNewPrice) {
			result.push(newPrice)
		}

		if (oldPrice.dateRange.to > newPrice.dateRange.to) {
			result.push({
				...oldPrice,
				dateRange: {
					from: utilDate.addDays(newPrice.dateRange.to, 1),
					to: oldPrice.dateRange.to,
				},
			})
		}

		return result
	}

	public getInsertionData(elements: BasePrice[], newPrice: BasePrice): RangeInsertionData {
		const isFirstElement = elements.length == 0 || utilDate.isBefore(newPrice.dateRange.to, elements[0].dateRange.from)

		if (isFirstElement) {
			return {
				isFirstElement,
			}
		}

		const isLastElement = utilDate.isAfter(newPrice.dateRange.from, elements[elements.length - 1].dateRange.to)
		if (isLastElement) {
			return {
				isLastElement,
			}
		}

		let fromIndex = -1
		let toIndex = -1

		for (let i = 0; i < elements.length; i++) {
			const element = elements[i]

			const isFullMatch =
				newPrice.dateRange.from >= element.dateRange.from && newPrice.dateRange.to <= element.dateRange.to
			if (isFullMatch) {
				// New price is within the range of an existing element
				fromIndex = i
				toIndex = i
				break
			}

			// starts before the current element's end
			if (
				utilDate.dateInRange(newPrice.dateRange.from, element.dateRange) ||
				element.dateRange.from <= newPrice.dateRange.from
			) {
				fromIndex = i
			}

			// New price ends within the current element
			if (
				utilDate.dateInRange(newPrice.dateRange.to, element.dateRange) ||
				element.dateRange.from <= newPrice.dateRange.to
			) {
				toIndex = i
			}
		}

		const arrLength = elements.length - 1
		if (newPrice.dateRange.to > elements[arrLength].dateRange.to) {
			toIndex = arrLength + 1
		}

		return {
			positionInfo: {
				from: fromIndex,
				to: toIndex,
			},
		}
	}
}

export const utilBasePrices = new UtilBasePrices()
