<script lang="ts" setup>
import { useBannerStore } from '~~/src/store/banners'
import MessageCard from './MessageCard.vue'

const bannerStore = useBannerStore()
const onClose = (event: Function | undefined, index: number) => {
	bannerStore.closeBannerAtIndex(index)

	event ? event() : null
}

const banners = computed(() => bannerStore.banners)
</script>

<template>
	<div class="mt-4 flex flex-col gap-2 px-6" v-if="banners.length > 0">
		<MessageCard
			v-for="(banner, index) in banners"
			:card-data="banner"
			@close="(e) => onClose(e, index)"
			:inline-link="true"
		/>
	</div>
</template>
