<script lang="ts" setup>
import { PropType } from 'vue';
import { TranslationKeys } from '~/i18n/TranslationKeys';
import { IdModal } from '~~/src/constants/IdModal';
import { TextSizes } from '~~/src/constants/textSizes';
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration';
import { ITextParagraph } from '~~/src/types/ITextParagraph';

const props = defineProps({
    config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
    modalId: { type: String as PropType<IdModal>, required: true }
})
const { config, modalId } = toRefs(props)

const content: ITextParagraph[] = [
    { title: TranslationKeys.BASE_PRICE, description: TranslationKeys.MODIFIERS_HELP_BASE_PRICE_TEXT },
    { title: TranslationKeys.MARKET_TREND, description: TranslationKeys.MODIFIERS_HELP_MARKET_TREND_TEXT },
    { title: TranslationKeys.STRUCTURE_TREND, description: TranslationKeys.MODIFIERS_HELP_STRUCTURE_TREND_TEXT },
    { title: TranslationKeys.DATE_DISTANCE, description: TranslationKeys.MODIFIERS_HELP_DATE_DISTANCE_TEXT },
    { title: TranslationKeys.EVENTS, description: TranslationKeys.MODIFIERS_HELP_EVENTS_TEXT },
]
</script>

<template>
    <ModalGeneric :modal-id="modalId" :config="config" style="width: 600px">
        <div class="flex flex-col gap-5">
            <div v-for="entry in content" class="flex flex-col">
                <CommonText :text-size="TextSizes.BODY_SEMIBOLD" :text="entry.title" />
                <CommonText :text-size="TextSizes.BODY_REGULAR" :text="entry.description" />
            </div>
        </div>
    </ModalGeneric>
</template>