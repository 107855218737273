<template>
  <div class="inline-block bg-light-blue-800" :class="roundedContainer">
    <div ref="reference" class="p-1 truncate border-l-[1px] my-[1px] border-x-light-blue-800" :class="getAdditionalClasses">
      <CommonText v-if="!hiddenText" :text="(el.readableWeight as TranslationKey)" class="font-semibold truncate" />
    </div>
    <div v-if="isHovered && hiddenText" ref="floating" :style="floatingStyles"
      class="absolute top-0 left-0 z-50 flex gap-2 p-2 mt-2 text-white rounded-sm bg-dark-blue-700">
      <span>{{ el.readableWeight }}</span>
      <span>|</span>
      <span>{{ readableStartDate() }}</span>
      <span v-if="readableEndDate()"> → {{ readableEndDate() }}</span>
    </div>
  </div>
  <div class="flex justify-between w-full pb-1 whitespace-nowrap">
    <CommonText v-if="!hiddenText"
      :text="(utilDate.formatYearlessShortLocale(el.startDate, currentLocale) as TranslationKey)" />
    <CommonText v-if="(!hiddenText && index == (detailedImpacts.length - 1) && el.numberOfDays > 1)"
      :text="(utilDate.formatYearlessShortLocale(addDays(el.startDate, el.numberOfDays - 1), currentLocale) as TranslationKey)" />
  </div>
</template>

<script setup lang="ts">
import { addDays } from 'date-fns';
import { PropType, ref } from 'vue';
import { useElementBounding, useElementHover } from '@vueuse/core'
import { eventDetailsConfig, IColorThreshold } from '~~/src/config/EventDetailsConfig'
import { EventType } from '~~/src/submodules/sharedTypes/common/Event'
import { utilDate } from '~~/src/utils/utilDate'
import { useFloating } from '@floating-ui/vue'
import { TranslationKey } from '~/i18n/TranslationKeys';

const props = defineProps({
  index: { type: Number, required: true },
  detailedImpacts: { type: Array as PropType<IimpactIndicator[]>, required: true },
  eventType: { type: String as PropType<EventType>, required: true },
  el: { type: Object as PropType<IimpactIndicator>, required: true },
  hiddenText: { type: Boolean, default: false },
  hovering: { type: Number, default: -1 },
  isLast: { type: Boolean, default: false }
})
const { index, el, detailedImpacts, eventType, hiddenText, hovering, isLast } = toRefs(props)

const emit = defineEmits(['hideText', 'isHovered'])
const currentLocale = useLocale().currentLocale

interface IimpactIndicator {
  startDate: Date,
  numberOfDays: number,
  weight: number,
  readableWeight: String
}

const readableStartDate = () => {
  return utilDate.formatYearlessShortLocale(el.value.startDate, currentLocale.value)
}
const readableEndDate = () => {
  if (el.value.numberOfDays == 1) {
    return ''
  }
  return utilDate.formatYearlessShortLocale(addDays(el.value.startDate, el.value.numberOfDays - 1), currentLocale.value)
}

const reference = ref(null)
const floating = ref(null)
const { floatingStyles } = useFloating(reference, floating)

const roundedContainer = computed(() => {
  if (index.value == 0) {
    return 'rounded-l'
  }
  if (index.value == detailedImpacts.value.length - 1) {
    return 'rounded-r'
  }
  return ''
})

const isHovered = useElementHover(reference)
watch(isHovered, (value) => {
  if (value) {
    emit('isHovered', index.value)
  } else {
    emit('isHovered', -1)
  }
})

const getAdditionalClasses = computed(() => {

  const weight = detailedImpacts.value[index.value].weight
  const thresholdArrayToUse = (eventDetailsConfig.colors[eventType.value] as IColorThreshold[])

  const colorthreshold: IColorThreshold = thresholdArrayToUse
    .find(el => el.upTo >= weight)
    || thresholdArrayToUse[thresholdArrayToUse.length - 1]

  let base = `${colorthreshold.background} ${colorthreshold.text}`


  if (hiddenText.value) {
    base += ' h-7'
  }
  if (isLast.value) {
    base += ' border-r-[1px]'
  }
  if (detailedImpacts.value.length == 1) {
    base += ' rounded'
  } else  if (index.value == 0) {
    base += ' rounded-l'
  } else if (index.value == detailedImpacts.value.length - 1) {
    base += ' rounded-r'
  }
  
  if ((hovering.value >= 0 && hovering.value != index.value) && hiddenText.value) {
    return base + ' opacity-30 transition-opacity duration-200'
  }
  return base
  // }
})


const { width } = useElementBounding(reference)
onMounted(async () => {
  if (width?.value < 64) {
    emit('hideText', true)
  }
})

</script>
