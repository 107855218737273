<script setup lang="ts">
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { CardTypes } from '~~/src/constants/cardTypes'
import { useEventsStore } from '~~/src/store/events'
import { EventType, RepetitionType } from '~~/src/submodules/sharedTypes/common/Event'
import { IMessageCardData } from '~~/src/types/IMessageCardData'

const eventStore = useEventsStore()

// name
const onNameChange = (name: string) => {
	// TODO: improve
	eventStore.editableEvent.label.de = name
	eventStore.editableEvent.label.en = name
	eventStore.editableEvent.label.it = name
}

// @ts-ignore
const name = computed(() => eventStore.editableEvent.label[useLocale().currentLocale.value])

// event type
eventStore.editableEvent.eventType = EventType.Event
const events = computed(() => [useLocale().translate(TranslationKeys.EVENTO)])
const selectedEventType = ref(0)
const placeholder = computed(() =>
	eventStore.editableEvent.eventType == EventType.Event
		? TranslationKeys.INSERT_EVENT_NAME
		: TranslationKeys.INSERT_HOLIDAY_NAME
)

// repetition
if (eventStore.editableEventDetails.repetitionType == undefined) {
	eventStore.editableEventDetails.repetitionType = RepetitionType.NoRepeat
}
const repetitionValues = [RepetitionType.None, RepetitionType.Custom]
const repetitionLabels = [
	useLocale().translate(TranslationKeys.NO_REPETITION),
	useLocale().translate(TranslationKeys.CUSTOM_REPETITION),
]
const selectedRepetition = ref(
	Math.max(
		repetitionValues.findIndex((el) => el == eventStore.editableEventDetails.repetitionType),
		0
	)
)
const onRepetitionChange = (index: number) => {
	selectedRepetition.value = index
	eventStore.editableEventDetails.repetitionType = repetitionValues[index]
}

const cardData = computed(
	(): IMessageCardData => ({
		canClose: false,
		cardType: CardTypes.INFO,
		title:
			selectedRepetition.value == 1
				? TranslationKeys.ANNUAL_REPETITION
				: TranslationKeys.ANNUAL_REPETITION_DIFFERENT_DATES,
		message:
			selectedRepetition.value == 1
				? TranslationKeys.ANNUAL_REPETITION_TEXT
				: TranslationKeys.ANNUAL_REPETITION_DIFFERENT_DATES_TEXT,
	})
)
const isExistingEvent = computed(() => eventStore.editableEvent.accommodationId != undefined)
const isUserGenerated = computed(() => !isExistingEvent.value || eventStore.editableEvent.isUserGenerated)

const onAccommodationsChange = (selectedAccommodations: number[]) =>
	(eventStore.selectedAccommodations = selectedAccommodations)
</script>

<template>
	<div class="flex flex-col gap-4">
		<!-- input fields -->
		<div class="flex w-full gap-2">
			<CommonLabel class="w-full flex-1" :text="TranslationKeys.NAME">
				<CommonTextInput
					class="w-full"
					@change="onNameChange"
					:value="name"
					:placeholder="placeholder"
					:disabled="!isUserGenerated"
				/>
			</CommonLabel>
		</div>
		<div class="flex w-full gap-2">
			<CommonLabel class="flex-1" :text="TranslationKeys.TYPE">
				<CommonDropdown :entries="events" :selected-entry="selectedEventType" :disabled="true" />
			</CommonLabel>
			<CommonLabel class="flex-1" :text="TranslationKeys.REPETITION">
				<CommonDropdown
					:entries="repetitionLabels"
					:selected-entry="selectedRepetition"
					@click="onRepetitionChange"
					:disabled="isExistingEvent"
				/>
			</CommonLabel>
		</div>

		<!-- info banner-->
		<div v-if="selectedRepetition > 0">
			<CommonMessageCard :card-data="cardData" shadow="shadow-md" />
		</div>

		<!-- accommodation selector -->
		<div class="pt-2">
			<AccommodationList
				@change="onAccommodationsChange"
				:disabled="isExistingEvent"
				:highlighted-accommodation-id="
					eventStore.editableEvent.accommodationId || eventStore.eventInteractionAccommodationId
				"
				:selected-accommodations="
					isExistingEvent ? [eventStore.editableEvent.accommodationId] : eventStore.selectedAccommodations
				"
				:disable-derived-accommodations="true"
			/>
		</div>
	</div>
</template>
