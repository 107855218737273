<script lang="ts">
import { PropType } from 'vue'
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration'
import { IMinimalButton } from '~~/src/types/IMinimalButton'
import { Ref } from 'vue'
import { IdModal } from '~~/src/constants/IdModal'
import { TranslationKey } from '~/i18n/TranslationKeys'

export default {
	props: {
		config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
		modalId: { type: String as PropType<IdModal>, required: true },
	},
	setup(props: any) {
		// @ts-ignore
		const config: Ref<IGenericModalConfiguration> = ref(props.config as IGenericModalConfiguration)

		const val = ref('')
		const onChange = (newVal: string) => (val.value = newVal)
		const onClickOverride = (onClick: (params: any) => void) => onClick(val.value)

		const buttons =
			config.value.buttons == undefined
				? undefined
				: config.value.buttons.map(
						(button) =>
							({
								text: button.text as TranslationKey,
								effect: () => onClickOverride(button.effect),
							}) as IMinimalButton
					)
		const configToForward = { ...config.value, buttons }
		const errorCondition = computed(() => config.value.inputInfos?.error)
		const placeholder = computed(() => config.value.inputInfos?.placeholder)
		const label = computed(() => config.value.inputInfos?.label)

		return {
			val,
			onChange,
			errorCondition,
			configToForward,
			placeholder,
			label,
		}
	},
}
</script>

<template>
	<ModalGeneric :modal-id="modalId" :config="configToForward">
		<CommonEmailInput
			class="w-full"
			:value="val"
			@change="onChange"
			:placeholder="placeholder"
			:error-condition="errorCondition"
			:label="label?.key"
			input-test-id="LoginForgotEmailField"
		/>
	</ModalGeneric>
</template>
