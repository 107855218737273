<script lang="ts" setup>
import { PropType } from 'vue';
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys';
import { CardTypes } from '~~/src/constants/cardTypes';
import { IdModal } from '~~/src/constants/IdModal';
import { useAccommodationsStore } from '~~/src/store/accommodations';
import { useEventsStore } from '~~/src/store/events';
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration';
import { IMessageCardData } from '~~/src/types/IMessageCardData';

const props = defineProps({
    config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
    modalId: { type: String as PropType<IdModal>, required: true }
})
const { config } = toRefs(props)

const eventStore = useEventsStore()
const accommodationStore = useAccommodationsStore()

const indexedRooms = eventStore.selectedAccommodations
    .map(accommodationId => accommodationStore.getRoomTypesByAccommodationId(accommodationId)
        .filter(roomType => roomType.isIndexed))
    .reduce((prev, next) => prev.concat(next), [])
const hasIndexedRooms = indexedRooms.length > 0

let message: string = TranslationKeys.MODIFIERS_DELETE_ON_EVENT_EDIT
if (hasIndexedRooms) {
    message = useLocale().translate(TranslationKeys.MODIFIERS_DELETE_MAY_IMPACT_THE_FOLLOWING)

    let roomNames = indexedRooms
        .map(el => `<li>${el.name}</li>`)
        .reduce((prev, next) => prev + next, '')

    message += `<ul class="flex flex-col gap-2 pt-3 pl-4 list-disc">${roomNames}</ul>`
}

const cardData: IMessageCardData = {
    canClose: false,
    cardType: CardTypes.WARNING,
    title: TranslationKeys.MODIFIERS_DETECTED,
    message: message as TranslationKey,
}

</script>

<template>
    <ModalGeneric :modal-id="modalId" :config="config" style="width: 600px;">
        <template v-slot:default>
            <CommonMessageCard :card-data="cardData" class="rounded-md" />
        </template>
    </ModalGeneric>
</template>