<script lang="ts" setup>
import { PropType, Ref } from 'vue'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { EmitsEnum } from '~~/src/constants/emits'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useModifiersStore } from '~~/src/store/modifiers'
import { DateRange } from '~~/src/submodules/sharedTypes/common/DateRange'
import { Modifiers, ModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'

const props = defineProps({
	dateRange: { type: Object as PropType<DateRange>, required: true },
	selectedAccommodationId: Number,
	allowEdit: { type: Boolean, default: true },
})
const { dateRange, selectedAccommodationId, allowEdit } = toRefs(props)

// stores and emit
const modifiersStore = useModifiersStore()
const accommodationStore = useAccommodationsStore()
const emit = defineEmits([
	EmitsEnum.Change,
	EmitsEnum.ErrorStatusChange,
	EmitsEnum.Cancel,
	EmitsEnum.AccommodationIdChange,
])

// currency
const currency = computed(() => accommodationStore.getAccommodationById(selectedAccommodationId?.value)!.currency)

const editableModifiers = [ModifierType.Tweak]
const getCheckboxProps = (id: number) => {
	const roomType = accommodationStore.getRoomTypeById(id)

	// hint if derived
	let hint = undefined
	if (roomType?.linkedRoomType != undefined) {
		hint = useLocale().translate(TranslationKeys.ROOM_DERIVED_FROM, [roomType!.linkedRoomType!.name || ''])
	}

	const hasModifiers =
		roomType != undefined &&
		roomType.editableModifiers.length > 0 &&
		editableModifiers.every((editable) => roomType.editableModifiers.includes(editable))

	return {
		disabled: roomType == undefined || !hasModifiers,
		hint,
	}
}

// accommodation handling
const onAccommodationSelect = (newAccommodationId: number) => {
	emit(EmitsEnum.AccommodationIdChange, newAccommodationId)
	updateAvailableModifiers()
}

// roomType handling
const selectedRoomTypes: Ref<number[]> = ref([])
const onRoomTypeChange = (idList: number[]) => {
	selectedRoomTypes.value = idList
	emit(EmitsEnum.Change, currentModifiers.value, selectedRoomTypes.value)
}

// on modifiers handling
const onModifiersChange = (modifiers: Modifiers) => {
	currentModifiers.value = modifiers
	emit(EmitsEnum.Change, modifiers, selectedRoomTypes.value)
}
const currentModifiers: Ref<Modifiers> = ref({})
const updateAvailableModifiers = async () => {
	await nextTick()
	if (dateRange.value) {
		const roomTypeIds = accommodationStore
			.getRoomTypesByAccommodationId(selectedAccommodationId?.value!)
			.map((el) => el.id)
		modifiersStore.requestForRange(dateRange.value, roomTypeIds)
	}
}
watch(dateRange, updateAvailableModifiers)
onMounted(updateAvailableModifiers)

// errors handling
const onErrorStatusChange = (hasError: boolean) => emit(EmitsEnum.ErrorStatusChange, hasError)
const onModifierRemove = (modifier: ModifierType) => emit(EmitsEnum.Cancel, selectedRoomTypes.value, modifier)
const disabled = computed(() => !dateRange.value)
</script>

<template>
	<div>
		<AccommodationDropdownList
			:disabled="disabled"
			:selected-accommodation-id="selectedAccommodationId"
			@change="onRoomTypeChange"
			@select="onAccommodationSelect"
			:get-checkbox-props="getCheckboxProps"
			:test-ids="{
				'accommodation-dropdown': '',
				'search-input': '',
				'room-type-list': '',
				'select-all': '',
			}"
		/>
		<AnimationCollapse>
			<ModifiersManager
				v-if="allowEdit && selectedRoomTypes.length > 0"
				@cancel="onModifierRemove"
				@change="onModifiersChange"
				:modifiers-filters="editableModifiers"
				:currency="currency"
				@error-status-change="onErrorStatusChange"
				:removable-modifiers="modifiersStore.getCommonModifiersForRoomTypeId(selectedRoomTypes)"
				:associated-modifiers="[modifiersStore.getAssociatedModifiers(selectedRoomTypes)]"
			/>
		</AnimationCollapse>
	</div>
</template>
